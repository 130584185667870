<template>
  <div>
    <div class="wrapper">
      <CopyBox
        align="full"
        headline="Häufige"
        headlineBold="Fragen zu Wartung und Pflege"
      >
        <p>
          Hier haben wir häufige Fragen zu Wartung und Pflege des Allgäuer
          Luftbetts bzw. der Luftkernmatratze von Dynaglobe für Sie
          zusammengestellt:
        </p>
      </CopyBox>
      <CopyBox align="full">
        <AccordionItem
          v-for="(item, index) in faqs"
          :key="index"
          :title="item.question"
          :text="item.answer.html"
          :number="index"
        ></AccordionItem
      ></CopyBox>
      <CtaTeaser
        headline="Noch mehr"
        headlineBold="Fragen?"
        ctaLink="faq"
        ctaText="Zur FAQ Übersicht"
      >
      </CtaTeaser>
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventBus'
import CopyBox from '@/components/CopyBox.vue'
import AccordionItem from '@/components/AccordionItem.vue'
import CmsService from '@/services/CmsService'
import CtaTeaser from '@/components/CtaTeaser.vue'

export default {
  name: 'FaqMaintenancePage',
  data() {
    return {
      faqs: {}
    }
  },
  components: {
    CopyBox,
    AccordionItem,
    CtaTeaser
  },
  mounted() {
    EventBus.$emit('headline', 'Fragen zu')
    EventBus.$emit('headlineExt', 'Wartung und Pflege des Allgäuer Luftbetts')
  },
  created() {
    CmsService.getFaqs('faq-wartung-pflege').then(data => {
      this.faqs = data
    })
  }
}
</script>

<style lang="scss" scoped></style>
