<template>
  <div>
    <div class="wrapper">
      <BlogPost
        :align="isEven(index) ? 'left' : 'right'"
        v-for="(post, index) in posts"
        :key="post.id"
        :post="post"
      ></BlogPost>
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventBus'
import BlogPost from '@/components/BlogPost.vue'
import CNewsService from '@/services/CNewsService'

export default {
  name: 'BlogPage',
  components: {
    BlogPost
  },
  data() {
    return {
      posts: []
    }
  },
  methods: {
    isEven(postId) {
      if (postId % 2 == 0) {
        return true
      } else return false
    }
  },
  mounted() {
    EventBus.$emit('headline', 'Blog')
    EventBus.$emit('headlineExt', 'Neues vom Allgäuer Luftbett')
  },
  created() {
    CNewsService.getPosts().then(response => {
      this.posts = response.data
    })
    /* .catch(error => {
        console.log('Axios error: ' + error.response)
      }) */
  }
}
</script>

<style lang="scss"></style>
