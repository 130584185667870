<template>
  <div>
    <div class="wrapper">
      <CopyBox
        headline="Diese Seite"
        headlineBold="schläft wohl noch"
        align="left"
      >
        Wir konnten die angeforderte Seite leider nicht finden. Entweder
        existiert sie nicht mehr, oder es wurde ein falscher Link aufgerufen.
        Starten Sie doch einfach noch einmal von vorn.
      </CopyBox>
      <CopyBox>
        <CTA link="home" text="Zur Startseite"></CTA>
      </CopyBox>
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventBus'
import CopyBox from '@/components/CopyBox.vue'
import CTA from '@/components/CTA.vue'

export default {
  components: {
    CopyBox,
    CTA
  },
  mounted() {
    EventBus.$emit('headline', 'Ups!')
    EventBus.$emit('headlineExt', 'Hier stimmt was nicht: 404')
  }
}
</script>

<style lang="scss"></style>
