<template>
  <div class="post">
    <div class="postText" :class="align">
      <div class="postDate">{{ post.datum_de }}</div>
      <h2 class="headline">
        {{ slicedText(post.headline) }}
        <span class="highlight">{{ highlightText(post.headline) }}</span>
      </h2>
      <p>{{ post.teasertext }}</p>
      <p>
        <CTA
          text="weiterlesen"
          link="blogpost"
          dynamic
          :target="post.id"
          :slug="post.permalink"
        ></CTA>
      </p>
    </div>
    <div class="postImage">
      <img
        :src="
          'https://www.allgaeuer-luftbett.de/api/cnews/_images/posts/' +
            post.bild
        "
        :alt="post.headline"
      />
    </div>
  </div>
</template>

<script>
import CTA from '@/components/CTA.vue'

export default {
  components: {
    CTA
  },
  name: 'BlogPost',
  props: {
    align: {
      type: String,
      default: 'left'
    },
    post: Object
  },
  methods: {
    slicedText(string) {
      let resultArray = string.split(' ')
      resultArray = resultArray.slice(0, resultArray.length - 3)

      let text = resultArray.join(' ')

      return text
    },
    highlightText(string) {
      let highlight = string
        .split(' ')
        .slice(-3)
        .join(' ')

      return highlight
    }
  }
}
</script>

<style lang="scss" scoped>
.post {
  font-size: 18px;
  line-height: 1.5em;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  margin: 75px 0;

  @include bp-small {
    font-size: 16px;
    flex-flow: row wrap;
  }

  .postText,
  .postImage {
    flex: 0 0 50%;
    max-width: 50%;
    overflow: hidden;

    @include bp-small {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}
.postDate {
  font-size: 16px;
  font-weight: 900;
  color: $color-brown;

  @include bp-small {
    font-size: 14px;
  }
}
.postText {
  &.left {
    padding-right: 30px;
    order: -1;
  }
  &.right {
    padding-left: 30px;
    order: 1;
  }

  @include bp-small {
    &.left,
    &.right {
      order: 1;
      padding: 0;
    }
  }
}
.postImage {
  max-height: 50vh;
  overflow: hidden;

  img {
    width: 100%;
  }
}
.headline {
  margin-top: 5px;
  font-weight: 300;
  font-size: 30px;
  line-height: 1.25em;
}
</style>
