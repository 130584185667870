<template>
  <div>
    <div class="wrapper">
      <CopyBox align="full">
        <p>
          Verantwortliche Stelle im Sinne der Datenschutzgesetze, insbesondere
          der EU-Datenschutzgrundverordnung (DSGVO), ist:
        </p>
        <p>Ulrich Schmid</p>
        <h2>Ihre Betroffenenrechte</h2>
        <p>
          Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten
          können Sie jederzeit folgende Rechte ausüben:
        </p>
        <ul>
          <li>
            Auskunft über Ihre bei uns gespeicherten Daten und deren
            Verarbeitung,
          </li>
          <li>Berichtigung unrichtiger personenbezogener Daten,</li>
          <li>Löschung Ihrer bei uns gespeicherten Daten,</li>
          <li>
            Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund
            gesetzlicher Pflichten noch nicht löschen dürfen,
          </li>
          <li>Widerspruch gegen die Verarbeitung Ihrer Daten bei uns und</li>
          <li>
            Datenübertragbarkeit, sofern Sie in die Datenverarbeitung
            eingewilligt haben oder einen Vertrag mit uns abgeschlossen haben.
          </li>
        </ul>
        <p>
          Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese
          jederzeit mit Wirkung für die Zukunft widerrufen.
        </p>
        <p>
          Sie können sich jederzeit mit einer Beschwerde an die für Sie
          zuständige Aufsichtsbehörde wenden. Ihre zuständige Aufsichtsbehörde
          richtet sich nach dem Bundesland Ihres Wohnsitzes, Ihrer Arbeit oder
          der mutmaßlichen Verletzung. Eine Liste der Aufsichtsbehörden (für den
          nichtöffentlichen Bereich) mit Anschrift finden Sie unter:
          <a
            href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
            target="_blank"
            rel="noopener"
            >https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a
          >.
        </p>
        <h2>
          Zwecke der Datenverarbeitung durch die verantwortliche Stelle und
          Dritte
        </h2>
        <p>
          Wir verarbeiten Ihre personenbezogenen Daten nur zu den in dieser
          Datenschutzerklärung genannten Zwecken. Eine Übermittlung Ihrer
          persönlichen Daten an Dritte zu anderen als den genannten Zwecken
          findet nicht statt. Wir geben Ihre persönlichen Daten nur an Dritte
          weiter, wenn:
        </p>
        <ul>
          <li>Sie Ihre ausdrückliche Einwilligung dazu erteilt haben,</li>
          <li>
            die Verarbeitung zur Abwicklung eines Vertrags mit Ihnen
            erforderlich ist,
          </li>
          <li>
            die Verarbeitung zur Erfüllung einer rechtlichen Verpflichtung
            erforderlich ist,
          </li>
        </ul>
        <p>
          die Verarbeitung zur Wahrung berechtigter Interessen erforderlich ist
          und kein Grund zur Annahme besteht, dass Sie ein überwiegendes
          schutzwürdiges Interesse an der Nichtweitergabe Ihrer Daten haben.
        </p>
        <h2>Löschung bzw. Sperrung der Daten</h2>
        <p>
          Wir halten uns an die Grundsätze der Datenvermeidung und
          Datensparsamkeit. Wir speichern Ihre personenbezogenen Daten daher nur
          so lange, wie dies zur Erreichung der hier genannten Zwecke
          erforderlich ist oder wie es die vom Gesetzgeber vorgesehenen
          vielfältigen Speicherfristen vorsehen. Nach Fortfall des jeweiligen
          Zweckes bzw. Ablauf dieser Fristen werden die entsprechenden Daten
          routinemäßig und entsprechend den gesetzlichen Vorschriften gesperrt
          oder gelöscht.
        </p>
        <h2>Erfassung allgemeiner Informationen beim Besuch unserer Website</h2>
        <p>
          Wenn Sie auf unsere Website zugreifen, werden automatisch mittels
          eines Cookies Informationen allgemeiner Natur erfasst. Diese
          Informationen (Server-Logfiles) beinhalten etwa die Art des
          Webbrowsers, das verwendete Betriebssystem, den Domainnamen Ihres
          Internet-Service-Providers und ähnliches. Hierbei handelt es sich
          ausschließlich um Informationen, welche keine Rückschlüsse auf Ihre
          Person zulassen.
        </p>
        <p>
          Diese Informationen sind technisch notwendig, um von Ihnen
          angeforderte Inhalte von Webseiten korrekt auszuliefern und fallen bei
          Nutzung des Internets zwingend an. Sie werden insbesondere zu
          folgenden Zwecken verarbeitet:
        </p>
        <ul>
          <li>
            Sicherstellung eines problemlosen Verbindungsaufbaus der Website,
          </li>
          <li>Sicherstellung einer reibungslosen Nutzung unserer Website,</li>
          <li>Auswertung der Systemsicherheit und -stabilität sowie</li>
          <li>zu weiteren administrativen Zwecken.</li>
        </ul>
        <p>
          Die Verarbeitung Ihrer personenbezogenen Daten basiert auf unserem
          berechtigten Interesse aus den vorgenannten Zwecken zur Datenerhebung.
          Wir verwenden Ihre Daten nicht, um Rückschlüsse auf Ihre Person zu
          ziehen. Empfänger der Daten sind nur die verantwortliche Stelle und
          ggf. Auftragsverarbeiter.
        </p>
        <p>
          Anonyme Informationen dieser Art werden von uns ggfs. statistisch
          ausgewertet, um unseren Internetauftritt und die dahinterstehende
          Technik zu optimieren.
        </p>
        <h2>Newsletter</h2>
        <p>
          Auf Grundlage Ihrer ausdrücklich erteilten Einwilligung, übersenden
          wir Ihnen regelmäßig unseren Newsletter bzw. vergleichbare
          Informationen per E-Mail an Ihre angegebene E-Mail-Adresse.
        </p>
        <p>
          Für den Empfang des Newsletters ist die Angabe Ihrer E-Mail-Adresse
          ausreichend. Bei der Anmeldung zum Bezug unseres Newsletters werden
          die von Ihnen angegebenen Daten ausschließlich für diesen Zweck
          verwendet. Abonnenten können auch über Umstände per E-Mail informiert
          werden, die für den Dienst oder die Registrierung relevant sind
          (Beispielsweise Änderungen des Newsletterangebots oder technische
          Gegebenheiten).
        </p>
        <p>
          Für eine wirksame Registrierung benötigen wir eine valide
          E-Mail-Adresse. Um zu überprüfen, dass eine Anmeldung tatsächlich
          durch den Inhaber einer E-Mail-Adresse erfolgt, setzen wir das
          „Double-opt-in“-Verfahren ein. Hierzu protokollieren wir die
          Bestellung des Newsletters, den Versand einer Bestätigungsmail und den
          Eingang der hiermit angeforderten Antwort. Weitere Daten werden nicht
          erhoben. Die Daten werden ausschließlich für den Newsletterversand
          verwendet und nicht an Dritte weitergegeben.
        </p>
        <p>
          Die Einwilligung zur Speicherung Ihrer persönlichen Daten und ihrer
          Nutzung für den Newsletterversand können Sie jederzeit widerrufen. In
          jedem Newsletter findet sich dazu ein entsprechender Link. Außerdem
          können Sie sich jederzeit auch direkt auf dieser Webseite abmelden
          oder uns Ihren entsprechenden Wunsch über die am Ende dieser
          Datenschutzhinweise angegebene Kontaktmöglichkeit mitteilen.
        </p>
        <h2>Kontaktformular</h2>
        <p>
          Treten Sie bzgl. Fragen jeglicher Art per E-Mail oder Kontaktformular
          mit uns in Kontakt, erteilen Sie uns zum Zwecke der Kontaktaufnahme
          Ihre freiwillige Einwilligung. Hierfür ist die Angabe einer validen
          E-Mail-Adresse erforderlich. Diese dient der Zuordnung der Anfrage und
          der anschließenden Beantwortung derselben. Die Angabe weiterer Daten
          ist optional. Die von Ihnen gemachten Angaben werden zum Zwecke der
          Bearbeitung der Anfrage sowie für mögliche Anschlussfragen
          gespeichert. Nach Erledigung der von Ihnen gestellten Anfrage werden
          personenbezogene Daten automatisch gelöscht.
        </p>
        <h2>Cookie Richtlinie</h2>
        <div id="cookiefirst-policy-page"></div>
        <div>
          Diese Cookie-Richtlinie wurde erstellt und aktualisiert von der Firma
          <a href="https://cookiefirst.com">CookieFirst.com</a>.
        </div>

        <h2>Verwendung von Google Analytics</h2>
        <p>
          Diese Website benutzt Google Analytics, einen Webanalysedienst der
          Google Inc. (folgend: Google). Google Analytics verwendet sog.
          „Cookies“, also Textdateien, die auf Ihrem Computer gespeichert werden
          und die eine Analyse der Benutzung der Webseite durch Sie ermöglichen.
          Die durch das Cookie erzeugten Informationen über Ihre Benutzung
          dieser Webseite werden in der Regel an einen Server von Google in den
          USA übertragen und dort gespeichert. Aufgrund der Aktivierung der
          IP-Anonymisierung auf diesen Webseiten, wird Ihre IP-Adresse von
          Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union
          oder in anderen Vertragsstaaten des Abkommens über den Europäischen
          Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle
          IP-Adresse an einen Server von Google in den USA übertragen und dort
          gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese
          Informationen benutzen, um Ihre Nutzung der Webseite auszuwerten, um
          Reports über die Webseitenaktivitäten zusammenzustellen und um weitere
          mit der Websitenutzung und der Internetnutzung verbundene
          Dienstleistungen gegenüber dem Webseitenbetreiber zu erbringen. Die im
          Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse
          wird nicht mit anderen Daten von Google zusammengeführt.
        </p>
        <p>
          Die Zwecke der Datenverarbeitung liegen in der Auswertung der Nutzung
          der Website und in der Zusammenstellung von Reports über Aktivitäten
          auf der Website. Auf Grundlage der Nutzung der Website und des
          Internets sollen dann weitere verbundene Dienstleistungen erbracht
          werden. Die Verarbeitung beruht auf dem berechtigten Interesse des
          Webseitenbetreibers.
        </p>
        <p>
          Sie können die Speicherung der Cookies durch eine entsprechende
          Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch
          darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
          Funktionen dieser Website vollumfänglich werden nutzen können. Sie
          können darüber hinaus die Erfassung der durch das Cookie erzeugten und
          auf Ihre Nutzung der Webseite bezogenen Daten (inkl. Ihrer IP-Adresse)
          an Google sowie die Verarbeitung dieser Daten durch Google verhindern,
          indem sie das unter dem folgenden Link verfügbare Browser-Plugin
          herunterladen und installieren:
          <a
            href="http://tools.google.com/dlpage/gaoptout?hl=de"
            target="_blank"
            rel="noopener"
            >Browser Add On zur Deaktivierung von Google Analytics</a
          >.
        </p>
        <p>
          Zusätzlich oder als Alternative zum Browser-Add-On können Sie das
          Tracking durch Google Analytics auf unseren Seiten unterbinden, indem
          Sie
          <a
            title="Google Analytics Opt-Out-Cookie setzen"
            onClick="gaOptout();alert('Google Analytics wurde deaktiviert');"
            href="#"
            >diesen Link anklicken</a
          >. Dabei wird ein Opt-Out-Cookie auf Ihrem Gerät installiert. Damit
          wird die Erfassung durch Google Analytics für diese Website und für
          diesen Browser zukünftig verhindert, so lange das Cookie in Ihrem
          Browser installiert bleibt.
        </p>
        <h2>Verwendung von Scriptbibliotheken (Google Webfonts)</h2>
        <p>
          Um unsere Inhalte browserübergreifend korrekt und grafisch ansprechend
          darzustellen, verwenden wir auf dieser Website Scriptbibliotheken und
          Schriftbibliotheken wie z. B. Google Webfonts (<a
            href="http://www.google.com/webfonts/"
            target="_blank"
            rel="noopener"
            >https://www.google.com/webfonts/</a
          >). Google Webfonts werden zur Vermeidung mehrfachen Ladens in den
          Cache Ihres Browsers übertragen. Falls der Browser die Google Webfonts
          nicht unterstützt oder den Zugriff unterbindet, werden Inhalte in
          einer Standardschrift angezeigt.
        </p>
        <p>
          Der Aufruf von Scriptbibliotheken oder Schriftbibliotheken löst
          automatisch eine Verbindung zum Betreiber der Bibliothek aus. Dabei
          ist es theoretisch möglich – aktuell allerdings auch unklar ob und
          ggf. zu welchen Zwecken – dass Betreiber entsprechender Bibliotheken
          Daten erheben.
        </p>
        <p>
          Die Datenschutzrichtlinie des Bibliothekbetreibers Google finden Sie
          hier:
          <a
            href="https://www.google.com/policies/privacy/"
            target="_blank"
            rel="noopener"
            >https://www.google.com/policies/privacy/</a
          >
        </p>
        <h2>Verwendung von Google Maps</h2>
        <p>
          Diese Webseite verwendet Google Maps API, um geographische
          Informationen visuell darzustellen. Bei der Nutzung von Google Maps
          werden von Google auch Daten über die Nutzung der Kartenfunktionen
          durch Besucher erhoben, verarbeitet und genutzt. Nähere Informationen
          über die Datenverarbeitung durch Google können Sie
          <a
            href="http://www.google.com/privacypolicy.html"
            target="_blank"
            rel="noopener"
            >den Google-Datenschutzhinweisen</a
          >
          entnehmen. Dort können Sie im Datenschutzcenter auch Ihre persönlichen
          Datenschutz-Einstellungen verändern.
        </p>
        <p>
          Ausführliche Anleitungen zur Verwaltung der eigenen Daten im
          Zusammenhang mit Google-Produkten<a
            href="http://www.dataliberation.org/"
            target="_blank"
            rel="noopener"
          >
            finden Sie hier</a
          >.
        </p>
        <h2>Eingebettete YouTube-Videos</h2>
        <p>
          Auf einigen unserer Webseiten betten wir Youtube-Videos ein. Betreiber
          der entsprechenden Plugins ist die YouTube, LLC, 901 Cherry Ave., San
          Bruno, CA 94066, USA. Wenn Sie eine Seite mit dem YouTube-Plugin
          besuchen, wird eine Verbindung zu Servern von Youtube hergestellt.
          Dabei wird Youtube mitgeteilt, welche Seiten Sie besuchen. Wenn Sie in
          Ihrem Youtube-Account eingeloggt sind, kann Youtube Ihr Surfverhalten
          Ihnen persönlich zuzuordnen. Dies verhindern Sie, indem Sie sich
          vorher aus Ihrem Youtube-Account ausloggen.
        </p>
        <p>
          Wird ein Youtube-Video gestartet, setzt der Anbieter Cookies ein, die
          Hinweise über das Nutzerverhalten sammeln.
        </p>
        <p>
          Wer das Speichern von Cookies für das Google-Ad-Programm deaktiviert
          hat, wird auch beim Anschauen von Youtube-Videos mit keinen solchen
          Cookies rechnen müssen. Youtube legt aber auch in anderen Cookies
          nicht-personenbezogene Nutzungsinformationen ab. Möchten Sie dies
          verhindern, so müssen Sie das Speichern von Cookies im Browser
          blockieren.
        </p>
        <p>
          Weitere Informationen zum Datenschutz bei „Youtube“ finden Sie in der
          Datenschutzerklärung des Anbieters unter:
          <a
            href="https://www.google.de/intl/de/policies/privacy/"
            target="_blank"
            rel="noopener"
            >https://www.google.de/intl/de/policies/privacy/
          </a>
        </p>
        <h2><strong>Änderung unserer Datenschutzbestimmungen</strong></h2>
        <p>
          Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie
          stets den aktuellen rechtlichen Anforderungen entspricht oder um
          Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen,
          z.B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt
          dann die neue Datenschutzerklärung.
        </p>
        <h2><strong>Fragen an den Datenschutzbeauftragten</strong></h2>
        <p>
          Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine
          E-Mail oder wenden Sie sich direkt an die für den Datenschutz
          verantwortliche Person in unserer Organisation:
        </p>
        <p>
          <em>Die Datenschutzerklärung wurde mit dem </em
          ><a
            href="https://www.activemind.de/datenschutz/datenschutzhinweis-generator/"
            target="_blank"
            rel="noopener"
            ><em
              >Datenschutzerklärungs-Generator der activeMind AG erstellt</em
            ></a
          ><em>.</em>
        </p>
      </CopyBox>
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventBus'
import CopyBox from '@/components/CopyBox.vue'

export default {
  components: {
    CopyBox
  },
  mounted() {
    EventBus.$emit('headline', 'Datenschutz')
    EventBus.$emit('headlineExt', '')
  }
}
</script>

<style lang="scss"></style>
