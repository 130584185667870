import { gql } from 'graphql-request'

const query = () => gql`
  query getFaqBlocks {
    faqBlocks(orderBy: sort_ASC) {
      headline
      slug
      description
    }
  }
`

export default {
  query
}
