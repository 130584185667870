<template>
  <div :class="align">
    <h2 class="headline" v-if="headline">
      {{ headline }}
      <span class="highlight">{{ headlineBold }}</span>
    </h2>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'CopyBox',
  props: {
    align: {
      type: String,
      default: 'left'
    },
    headline: {
      type: String
    },
    headlineBold: {
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
.left,
.right,
.full {
  font-size: 18px;
  line-height: 1.5em;
  margin: 75px 0;
}
.left {
  width: 50%;
}
.right {
  margin-left: 50%;
  width: 50%;
}
.full {
  width: 100%;
}

.headline {
  font-weight: 300;
  font-size: 30px;
  line-height: 1.25em;
}
@include bp-small {
  .left,
  .right,
  .full {
    font-size: 16px;
    width: 100%;
  }
  .right {
    margin-left: 0;
  }
  .headline {
    font-size: 25px;
  }
}
</style>
