<template>
  <div>
    <div class="wrapper">
      <ImageBox
        align="right"
        filename="Allgaeuer_Luftbett_Versandkarton.jpg"
        headline="Infos zur Lieferung einer Luftkernmatratze"
        headlineBold="aus dem Hause Dynaglobe"
      >
        Wir liefern kostenfrei innerhalb Deutschlands sowie europa- und
        weltweit! Die genauen Preise für den Versand außerhalb Deutschlands
        erfahren Sie auf Anfrage. Die Lieferung einer Luftkernmatratze erfolgt
        per Spedition und dauert ca. drei bis vier Wochen – saisonale
        Abweichungen sind möglich. Den genauen Lieferpreis erfahren Sie bei der
        Angebotserstellung. Auch Überlängen und Sondergrößen sind auf Anfrage
        lieferbar – hierfür kann die Lieferzeit ein wenig länger dauern.
      </ImageBox>
      <ImageBox
        align="left"
        headline="Lieferung"
        headlineBold="nach Absprache"
        filename="Allgaeuer_Luftbett_Matratze.jpg"
      >
        <p>
          Bevor die Spedition bei Ihnen klingelt, erhalten Sie einen
          Liefertermin genannt: damit können Sie sich auf den Empfang einstellen
          und wir planen ein begleitendes Telefonat, in dem wir Sie bei Aufbau
          und Einbau in Ihr bestehendes Bettgestell unterstützen. Die
          Anlieferung durch die Spedition erfolgt bis zur Bordsteinkante der von
          Ihnen genannten Adresse. Auch eine Lieferung durch die Spedition bis
          ins Schlafzimmer ist möglich. Der Lieferpreis richtet sich nach dem
          Aufwand – bitte im Vorfeld erfragen.
        </p>
      </ImageBox>
      <ImageBox
        filename="Allgaeuer_Luftbett_Steuerung.jpg"
        align="right"
        headline="Was Sie zu unserer"
        headlineBold="Transportversicherung wissen sollten"
      >
        <p>
          Glücklicherweise kommen Transportschäden nur äußerst selten vor. Doch
          kein Problem. Falls es ausgerechnet bei Ihrer Bestellung doch einmal
          zu einem Transportschaden kommen sollte: Jedes Paket, dass die
          Dynaglobe®-Produktion verlässt, befindet sich unter dem Schutz einer
          Transportversicherung.
        </p>
        <p>
          Folgende Auflagen müssen von Ihnen erfüllt werden, damit ein
          Transportschaden während der Lieferung ganz sicher von der
          Versicherung übernommen wird:
        </p>
        <OrderedList>
          <li>
            Nehmen Sie das Paket nur unter Vorbehalt an, falls ein Paket aus
            unserem Haus äußerlichen Schaden aufweist.
          </li>
          <li>
            Machen Sie sofort ein Foto vom beschädigten Karton. Öffnen Sie den
            Karton erst im nächsten Schritt und fotografieren Sie dann das
            Produkt.
          </li>
          <li>
            Melden Sie uns einen Transportschaden bitte immer so schnell wie
            möglich und legen Sie Fotos bei.
          </li>
        </OrderedList>
      </ImageBox>
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventBus'
import OrderedList from '@/components/OrderedList.vue'
import ImageBox from '@/components/ImageBox.vue'

export default {
  components: {
    OrderedList,
    ImageBox
  },
  mounted() {
    EventBus.$emit('headline', 'Lieferung')
    EventBus.$emit('headlineExt', '')
  }
}
</script>

<style lang="scss"></style>
