<template>
  <div>
    <div class="wrapper">
      <ImageBox
        align="right"
        filename="Uli_Schmid_Schlafberatung.jpg"
        headline="Der Kern unserer Schlafberatung"
        headlineBold="sind Sie"
      >
        Eine Matratzenberatung beginnt für uns zunächst mit einer Analyse Ihrer
        <span class="highlight">Schlafgewohnheiten</span> und eventueller
        Probleme. Was uns von vielen anderen Matratzenhändlern unterscheidet:
        Bei uns gibt es eine ausführliche
        <span class="highlight">Schlafberatung im Sinne des Kunden</span> – kein
        rein zahlengetriebenes Verkaufsgespräch. Für eine ausführliche,
        individuelle und völlig unverbindliche (!) Schlafberatung mit Ihnen
        nehmen wir uns gerne
        <span class="highlight">ein bis zwei Stunden</span> Zeit.</ImageBox
      >
      <CopyBox
        align="right"
        headline="Gezielte Fragestellungen"
        headlineBold="bei der Schlafberatung"
      >
        Dabei stellen wir Ihnen Fragen – z. B. zu Ihrem Bett, Ihrer
        Lieblingsschlafposition oder ob Sie in der Nacht zu
        <span class="highlight">Schlafstörungen</span> oder zu Beschwerden am
        Bewegungsapparat neigen. Wir hören Ihnen zu, gehen während der
        Schlafberatung auf Sie ein, fragen nach und erörtern gemeinsam mit
        Ihnen, wie Sie Ihre
        <span class="highlight"
          >Schlafqualität spürbar und nachhaltig verbessern</span
        >
        können. Diese Zeitinvestition lohnt sich. Denn eine höhere
        Schlafqualität bedeutet gleichzeitig eine höhere Lebensqualität! Und
        natürlich gehen wir im Rahmen der Schlafberatung auch auf die
        <router-link :to="{ name: 'vorteile' }">Vorteile</router-link
        >&nbsp;unseres Schlafsystems ein.
      </CopyBox>
      <CopyBox
        align="left"
        headline="Kundenzufriedenheit steht für uns"
        headlineBold="an erster Stelle "
      >
        Denn mit diesem System lässt sich tatsächlich vielen Ursachen für
        Schlafprobleme entgegenwirken – lesen Sie doch gerne mal ein paar
        <span class="highlight">Erfahrungsberichte</span> in unseren
        <router-link :to="{ name: 'kundenstimmen' }">Kundenstimmen</router-link
        >. Doch Beratung heißt bei uns nicht zwangsläufig Verkauf! Denn ein
        wichtiger Teil unserer
        <router-link :to="{ name: 'herkunftsgeschichte' }"
          >Unternehmensphilosophie</router-link
        >&nbsp;ist, dass wir
        <span class="highlight">keinerlei Verkaufsdruck</span> auf Sie ausüben.
        Sie kaufen nur, wenn Sie wirklich zu 100% von unserer Schlaflösung
        überzeugt sind – genau aus diesem Grund erreichen wir eine sehr hohe
        Kundenzufriedenheit und dürfen uns über gute Bewertungen, Lob per E-Mail
        und Telefon sowie zahlreiche Weiterempfehlungen freuen.
      </CopyBox>
      <ImageBox
        align="left"
        filename="Allgaeuer_Luftbett_Mobil.jpg"
        headline="Qualifizierte Schlafberatung –"
        headlineBold="damit es Ihr „Bett fürs Leben“ wird"
      >
        Auf Wunsch helfen wir Ihnen bei der Auswahl eines Luftschlafsystems und
        stellen Ihnen Ihr
        <span class="highlight">individuelles „Bett fürs Leben“</span> aus den
        für Ihren Bedarf passenden Komponenten zusammen. Sollten Sie sich für
        die Anschaffung unserer Luftkernmatratze entscheiden, begleiten wir Sie
        während der Eingewöhnungsphase telefonisch. Mit unseren Hinweisen werden
        sowohl der Aufbau als auch der
        <router-link :to="{ name: 'einbau' }">Einbau</router-link>&nbsp;in Ihr
        Bettgestell auch für Sie kinderleicht.
      </ImageBox>
      <CopyBox
        align="left"
        headline="Telefonservice während der"
        headlineBold="Eingewöhnungsphase"
      >
        Der
        <span class="highlight">Telefonservice</span> umfasst mehrere
        Telefonate, in denen wir Ihnen Tipps zum Einstellen geben, gemeinsam mit
        Ihnen optimale Werte für Ihren Bewegungsapparat ermitteln und Ihnen
        zeigen, was mit Ihrer Luftkernmatratze alles möglich ist. Sie werden
        begeistert sein! Wir sind für Sie da, solange und so oft Sie uns
        brauchen. Auch wenn nach mehreren Jahren Probleme oder Fragen auftauchen
        sollten – für uns endet die Kundenbeziehung nicht mit dem Verkauf. ;-)
      </CopyBox>
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventBus'
import CopyBox from '@/components/CopyBox.vue'
import ImageBox from '@/components/ImageBox.vue'

export default {
  components: {
    CopyBox,
    ImageBox
  },
  mounted() {
    EventBus.$emit('headline', 'Schlafberatung,')
    EventBus.$emit('headlineExt', 'die den Unterschied macht')
  }
}
</script>

<style lang="scss"></style>
