<template>
  <div class="container">
    <div class="caption" :class="align">
      <h2 class="headline" v-if="headline">
        {{ headline }}
        <span class="highlight">{{ headlineBold }}</span>
      </h2>
      <p><slot></slot></p>
    </div>
    <div class="image">
      <img :src="require(`../assets/content/${this.filename}`)" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageBox',
  props: {
    align: {
      type: String,
      default: 'left'
    },
    filename: String,
    headline: String,
    headlineBold: String
  }
}
</script>

<style lang="scss" scoped>
.container {
  font-size: 18px;
  line-height: 1.5em;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  margin: 75px 0;

  @include bp-small {
    font-size: 16px;
    flex-flow: row wrap;
  }

  .caption,
  .image {
    flex: 0 0 50%;
    max-width: 50%;
    overflow: hidden;
    box-sizing: border-box;

    @include bp-small {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}
.caption {
  &.left {
    padding-left: 50px;
    order: 1;
  }
  &.right {
    padding-right: 50px;
    order: -1;
  }

  @include bp-small {
    &.left,
    &.right {
      padding: 0;
    }
  }
}
.image {
  overflow: hidden;

  img {
    width: 100%;
  }
}
.headline {
  margin-top: 5px;
  font-weight: 300;
  font-size: 30px;
  line-height: 1.25em;
}
</style>
