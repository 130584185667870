import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    nav: { isNavOpen: false }
  },
  mutations: {
    TOGGLE_NAV(state) {
      state.nav.isNavOpen = !state.nav.isNavOpen
    }
  },
  actions: {}
})
