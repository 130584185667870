<template>
  <div id="nav" :class="themeColor">
    <nav>
      <router-link :to="{ name: 'allgaeuer-luftbett' }"
        >Allgäuer Luftbett</router-link
      >
      <router-link :to="{ name: 'service' }">Service</router-link>
      <router-link :to="{ name: 'faq' }">Häufige Fragen</router-link>
      <router-link :to="{ name: 'ueber-uns' }">Über Uns</router-link>
      <router-link :to="{ name: 'blog' }">Blog</router-link>
      <router-link :to="{ name: 'kontakt' }">Kontakt</router-link>
    </nav>
    <div class="subnav" v-show="subNavLuftbett">
      <router-link :to="{ name: 'vorteile' }">Vorteile</router-link>
      <router-link :to="{ name: 'pflege' }">Pflege</router-link>
      <router-link :to="{ name: 'preise' }">Preise</router-link>
      <router-link :to="{ name: 'garantie' }">Garantie</router-link>
      <router-link :to="{ name: 'einbau' }">Einbau</router-link>
    </div>
    <div class="subnav" v-show="subNavService">
      <router-link :to="{ name: 'schlafberatung' }">Schlafberatung</router-link>
      <router-link :to="{ name: 'schlafprofil' }"
        >Schlafprofil erstellen</router-link
      >
      <router-link :to="{ name: 'probeliegen' }">Probeliegen</router-link>
      <router-link :to="{ name: 'lieferung' }">Lieferung</router-link>
    </div>
    <div class="subnav" v-show="subNavAbout">
      <router-link :to="{ name: 'herkunftsgeschichte' }"
        >Herkunftsgeschichte</router-link
      >
      <router-link :to="{ name: 'kundenstimmen' }">Kundenstimmen</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavBar',
  computed: {
    themeColor: function() {
      return this.$route.meta.theme
    },
    subNavLuftbett: function() {
      if (this.$route.meta.subnav == 'luftbett') {
        return true
      } else return false
    },
    subNavService: function() {
      if (this.$route.meta.subnav == 'service') {
        return true
      } else return false
    },
    subNavAbout: function() {
      if (this.$route.meta.subnav == 'ueber-uns') {
        return true
      } else return false
    }
  }
}
</script>

<style lang="scss" scoped>
#nav {
  @include bp-medium {
    display: none;
  }
  position: relative;
  padding: 40px 0;

  nav {
    display: block;
  }
  a {
    color: white;
    text-decoration: none;
    margin-right: 20px;
    font-size: 20px;

    &.router-link-exact-active {
      font-weight: bold;
    }
  }
  &.dark a {
    color: $color-brown;
  }
  .subnav {
    display: block;
    margin-top: 10px;
    a {
      font-size: 17px;
    }
  }
}
</style>
