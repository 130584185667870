import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuelidate from 'vuelidate'
import BaseInput from '@/components/BaseInput.vue'
import BaseTextarea from '@/components/BaseTextarea.vue'
import BaseButton from '@/components/BaseButton.vue'
import BaseCheckbox from '@/components/BaseCheckbox.vue'
import VueGtag from 'vue-gtag'
import ConsentService from './services/ConsentService'

Vue.component('BaseInput', BaseInput)
Vue.component('BaseTextarea', BaseTextarea)
Vue.component('BaseButton', BaseButton)
Vue.component('BaseCheckbox', BaseCheckbox)
Vue.use(Vuelidate)

Vue.use(
  VueGtag,
  {
    config: {
      id: 'UA-44181514-2',
      params: {
        anonymize_ip: true
      }
    },
    enabled: false
  },
  router
)

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  let checkConsent = ConsentService.checkConsent('performance')

  if (checkConsent === true) {
    Vue.$gtag.optIn()
  }

  // set SEO metas
  document.title = to.meta.title || 'Allgäuer Luftbett'
  document
    .querySelector('head meta[name="description"]')
    .setAttribute('content', to.meta.description)
  next()
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
