import { gql } from 'graphql-request'

const query = () => gql`
  query Teaser($type: TeaserType!) {
    teasers(where: { type: $type }, orderBy: publishedAt_DESC) {
      id
      text {
        html
      }
    }
  }
`

export default {
  query
}
