<template>
  <footer id="footer">
    <div class="wrapper">
      <div class="footer-contact">
        <div class="footer-headline">
          <h2>
            Wir sind
            <span class="highlight">jederzeit für Sie da.</span>
          </h2>
          <div class="address">
            <a
              href="https://www.google.com/maps/place/Allg%C3%A4uer+Luftbett/@47.6768438,10.326445,17z/data=!3m1!4b1!4m5!3m4!1s0x479c7ea17f6574d9:0xc6149515e2d2932b!8m2!3d47.6768438!4d10.3286339"
              target="_blank"
            >
              <span class="company"> Allgäuer Luftbett </span>
              <span class="address-details">Felchenweg 4, Sulzberg</span></a
            >
          </div>
        </div>
        <div class="footer-cta">
          <CTA text="Kontakt aufnehmen" link="kontakt" inverted></CTA>
          <div class="phone-numbers">
            <div class="phone-number">
              <a href="tel:+4983768592">
                +49 (0)8376
                <span class="highlight">8592</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-links">
        <router-link :to="{ name: 'impressum' }">Impressum</router-link>
        <router-link :to="{ name: 'datenschutz' }">Datenschutz</router-link>
      </div>
    </div>
  </footer>
</template>

<script>
import CTA from '@/components/CTA.vue'

export default {
  components: {
    CTA
  },
  name: 'MainFooter'
}
</script>

<style lang="scss" scoped>
#footer {
  background-color: $color-dark-green;
  margin-top: 75px;
  padding-top: 60px;
  padding-bottom: 30px;
  color: $color-white;

  h2 {
    margin: 0;
    font-weight: 300;
    font-size: 30px;
  }
  a,
  a:visited,
  a:active {
    text-decoration: none;
  }
}
.footer-contact {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.footer-headline {
  width: 50%;
  display: inline-block;

  .address {
    margin-top: 15px;
    font-size: 1.1em;

    .company {
      margin-right: 5px;
      font-weight: 700;
    }

    a {
      color: $color-white;
    }
  }
}
.footer-cta {
  width: 50%;
  display: inline-block;
  font-size: 18px;
  text-align: center;
  line-height: 1.5em;

  .phone-numbers {
    margin-top: 15px;
  }
  .phone-number {
    font-size: 20px;

    a,
    a:active,
    a:visited {
      color: $color-white;
    }
  }
}
.footer-links {
  margin-top: 50px;
  text-align: right;

  a,
  a:visited,
  a:active {
    padding-left: 20px;
    color: $color-light-green;
  }
}
@include bp-small {
  #footer {
    padding-top: 40px;

    h2 {
      font-size: 25px;
    }
  }
  .footer-contact {
    flex-flow: row wrap;
  }
  .footer-headline,
  .footer-cta {
    width: 100%;
  }
  .footer-cta {
    margin-top: 40px;

    .phone-number {
      font-size: 16px;
    }
  }
}
</style>
