<template>
  <div>
    <div class="wrapper">
      <CopyBox
        headline="Unsere Checkliste zur"
        headlineBold="Analyse Ihrer Schlafgewohnheiten"
      >
        Haben Sie Interesse an einem Allgäuer Luftbett? Wenn Sie uns bereits im
        Vorfeld ein paar Fragen zu Ihren Schlafgewohnheiten beantworten (z. B.
        ob Sie sich eher als Seitenschläfer, Bauchschläfer oder Rückenschläfer
        sehen), können wir Ihnen ein persönliches Schlafprofil erstellen, im
        Beratungsgespräch von Anfang an bedarfsgenau auf Sie eingehen und ein
        passgenaues Angebot erstellen, das Ihnen zu höherer Schlafqualität und
        ergonomisch besserem Schlaf verhilft. Selbstverständlich gehen wir
        diskret mit Ihren uns anvertrauten Angaben um.
      </CopyBox>
      <CopyBox align="right">
        Füllen Sie unseren kurzen Fragebogen jetzt gleich online aus, bei Fragen
        sind wir jederzeit gerne telefonisch und per E-Mail für Sie da.
      </CopyBox>
      <CopyBox align="right">
        <CTA
          text="Ihr persönliches Schlafprofil erstellen"
          external
          target="_blank"
          link="https://www.allgaeuer-luftbett.de/api/checkliste/"
        />
      </CopyBox>
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventBus'
import CopyBox from '@/components/CopyBox.vue'
import CTA from '@/components/CTA.vue'

export default {
  components: {
    CopyBox,
    CTA
  },
  mounted() {
    EventBus.$emit('headline', 'Persönliches Schlafprofil')
    EventBus.$emit('headlineExt', 'erstellen')
  }
}
</script>

<style lang="scss"></style>
