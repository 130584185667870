<template>
  <div class="headline-stage">
    <h1 :class="themeColor">
      {{ h1 }}
      <span v-if="h1e">{{ h1e }}</span>
    </h1>
    <h2 :class="themeColor">{{ h2 }}</h2>
  </div>
</template>

<script>
export default {
  name: 'StageHeadline',
  props: {
    h1: String,
    h1e: String,
    h2: String
  },
  computed: {
    themeColor: function() {
      return this.$route.meta.theme
    }
  }
}
</script>

<style lang="scss" scoped>
.headline-stage {
  position: absolute;
}
h1,
h2,
.h1,
.h2 {
  color: white;
  margin: 0;
  padding: 0;

  &.dark {
    color: $color-brown;
  }
}
h1 {
  font-size: 55px;
  font-weight: 900;
  padding-top: 100px;

  @include bp-small {
    font-size: 30px;
    padding-top: 50px;
  }

  span {
    font-size: 45px;
    font-weight: 300;
    display: block;

    @include bp-small {
      font-size: 30px;
    }
  }
}
h2 {
  font-size: 45px;
  font-weight: 300;

  @include bp-small {
    font-size: 25px;
  }
}
</style>
