<template>
  <span>
    <router-link
      v-if="dynamic"
      class="button"
      :class="isInverted"
      :to="{ name: link, params: { postid: target, slug: slug } }"
      >{{ text }}</router-link
    >
    <router-link
      v-if="!dynamic & !external"
      class="button"
      :class="isInverted"
      :to="{ name: link }"
      >{{ text }}</router-link
    >
    <a
      v-if="external"
      class="button"
      :class="isInverted"
      :href="link"
      :target="target"
      >{{ text }}</a
    >
  </span>
</template>

<script>
export default {
  name: 'CTA',
  props: {
    link: {
      type: String
    },
    text: {
      type: String
    },
    inverted: {
      type: Boolean,
      default: false
    },
    dynamic: Boolean,
    external: Boolean,
    target: String,
    slug: String
  },
  computed: {
    isInverted: function() {
      if (this.inverted === true) {
        return 'inverted'
      }
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
a.button,
a:visited.button,
a:active.button {
  box-sizing: border-box;
  padding: 13px 25px;
  border: 2px solid;
  border-radius: 50px;
  text-decoration: none;
  font-size: 18px;
  font-weight: 900;
  display: inline-block;
}
a {
  &.inverted {
    border-color: white;
    color: white;
  }
  &:hover {
    background-color: white;
    color: $color-brown;
  }
}
@include bp-small {
  a.button,
  a:visited.button,
  a:active.button {
    padding: 10px 20px;
    font-size: 16px;
    text-align: center;
  }
}
</style>
