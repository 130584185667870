<template>
  <div>
    <div class="wrapper">
      <CopyBox align="left">
        Garantiezeit: Die Garantieleistung beim Allgäuer Luftbett beträgt drei
        Jahre Vollgarantie und zehn Jahre gestaffelte Garantie auf die
        Schweißnähte des Luftkerns. Pro Nutzungsjahr verringert sich der
        Garantieanspruch um 10%.
      </CopyBox>
      <CopyBox
        align="right"
        headline="Infos zu"
        headlineBold="Garantie und Garantiebestimmungen"
      >
        Zwar beträgt die Garantiezeit „nur“ 10 Jahre. Mit der von uns
        empfohlenen Pflege ist die
        <span class="highlight">tatsächliche Lebensdauer</span> aber wesentlich
        länger. Die ersten von uns verkauften Allgäuer Luftbetten (seit 2003)
        sind immer noch im Privat- oder Hoteleinsatz und nach wie vor in gutem
        Zustand. Mit einer <span class="highlight">Luftkernmatratze</span> gibt
        es keine Fehlkäufe wegen falschem Härtegrad, weil es sich zu 100% an den
        Körper anpasst und der Härtegrad stufenlos einstellbar und
        <span class="highlight">jederzeit bedarfsgenau veränderbar</span>
        ist. Das Preis-Leistungsverhältnis halten wir für besonders gut, weil
        Sie ja viel länger „richtig gut“ liegen als auf anderen Matratzen.
      </CopyBox>
      <CopyBox
        headline="Luftkernmatratzen:"
        headlineBold="Garantiert länger besser liegen"
      >
        Das Füllmaterial Luft – gleichzeitig der Hauptträger – ermüdet nicht und
        somit
        <span class="highlight"
          >kann sich eine Luftkernmatratze nicht durchliegen</span
        >. Sie genießen also im Vergleich zu anderen Schlafsystemen einen
        ausgesprochen langen Liegekomfort. Erfahrungsgemäß ist die Lebensdauer
        dieses Schlafsystems sehr lange. So sind alle
        <span class="highlight">Einzelteile austauschbar</span> – z. B. bei
        Defekt oder bei Verschleiß. Weiterentwicklungen können nachträglich
        nach- oder umgerüstet werden. Und eventuelle Verschleißteile lassen sich
        jederzeit innerhalb kurzer Zeit austauschen.
      </CopyBox>
      <CopyBox
        align="right"
        headline="Für perfekten Liegekomfort –"
        headlineBold="wir sind jederzeit gut für Sie erreichbar"
      >
        Falls mal ein Problem auftreten sollte, melden Sie sich einfach
        jederzeit per Telefon oder E-Mail bei uns. Wir sind
        <span class="highlight">äußerst serviceorientiert</span>
        und helfen Ihnen auch lange nach dem Kauf eines Luftbettsystems
        jederzeit gerne zeitnah und unkompliziert weiter. Ggf. prüfen wir sofort
        für Sie, ob Sie einen Garantieanspruch haben. Wir stehen in engem
        Kontakt zum
        <span class="highlight">Hersteller Dyngalobe®</span>. Denn dem liegt Ihr
        Wohl als Kunde genauso am Herzen wie uns.
      </CopyBox>
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventBus'
import CopyBox from '@/components/CopyBox.vue'

export default {
  components: {
    CopyBox
  },
  mounted() {
    EventBus.$emit('headline', 'Allgäuer Luftbett')
    EventBus.$emit('headlineExt', 'Bis zu 10 Jahre Garantiezeit!')
  }
}
</script>

<style lang="scss"></style>
