<template>
  <div id="app">
    <InfoBox />
    <FullStage>
      <NavBar slot="nav" />
      <Burger slot="nav" />
      <Logo slot="logo" />
    </FullStage>
    <Sidebar></Sidebar>
    <router-view />
    <CallButton />
    <MainFooter />
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import Burger from '@/components/menu/BurgerMenu.vue'
import Sidebar from '@/components/menu/SidebarMenu.vue'
import FullStage from '@/components/FullStage.vue'
import InfoBox from '@/components/InfoBox.vue'
import Logo from '@/components/MainLogo.vue'
import CallButton from '@/components/CallButton.vue'
import MainFooter from '@/components/MainFooter.vue'

export default {
  components: {
    NavBar,
    Burger,
    Sidebar,
    FullStage,
    InfoBox,
    Logo,
    CallButton,
    MainFooter
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,900');

html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', Helvetica, Arial, sans-serif;
}
a {
  text-decoration: underline;
  color: black;
}

#app {
  font-family: 'Lato', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .wrapper {
    width: 75%;
    margin: 0 auto;

    @include bp-small {
      width: 85%;
    }
  }
  .highlight {
    font-weight: 900;
  }
}
</style>
