<template>
  <div>
    <div class="wrapper">
      <CopyBox align="full">
        <p>
          Wir werden oft gefragt: Welche Vorteile bietet ein Luftbett gegenüber
          einer Federkern-, Boxspring- Kaltschaum-, Latexmatratze, diversen
          Topperauflagen oder anderen Matratzenarten wie Gel- oder Wasserbetten?
          Sind Luftkernmatratzen eine gute Alternative? Ist dieses Schlafsystem
          bei Rückenschmerzen zu empfehlen?
        </p>
      </CopyBox>
      <CopyBox align="full">
        <OrderedList>
          <li>
            <span class="highlight"
              >Äußerst angenehmes, einzigartiges Liegegefühl</span
            >, das Sie schnell vermissen werden, wenn Sie z.B. auf Reisen mal
            wieder auf einer herkömmlichen Matratze schlafen müssen.
          </li>
          <li>
            Der
            <span class="highlight"
              >Härtegrad ist punktgenau individuell einstellbar</span
            >. … und jederzeit neu anpassbar – das ermöglicht Ihnen
            unterschiedliche Härtegrade auf Knopfdruck.
          </li>
          <li>
            <span class="highlight">100% Körperanpassung</span>. D.h. die
            Luftkernmatratze passt sich „körperformgebend“ an Sie und Ihren
            Bewegungsapparat an – und nicht umgekehrt.
          </li>
          <li>
            Der
            <span class="highlight">
              Luftkern ist in eine Schulter- und eine Beckenzone unterteilt </span
            >. Diese sind getrennt einstellbar („Orthoplus®-Technologie“) – das
            unterstützt eine ergonomisch sehr gute Lagerung.
          </li>
          <li>
            Unsere Luftkernmatratze ist hochwertig verarbeitet, äußert
            strapazierfähig und für ein Körpergewicht
            <span class="highlight">bis zu 240 Kilogramm</span>
            geeignet.
          </li>

          <li>
            <span class="highlight">Geeignet für jeden Untergrund</span> (egal
            ob Lattenrost, Holzbrett mit Löchern oder Palette). Falls Sie Ihren
            alten Lattenrost verwenden möchten, erfüllt dieser keine
            ergonomische Funktion mehr.
          </li>
          <li>
            Unser Luftschlafsystem ist äußerst
            <span class="highlight">leicht zu reinigen</span> – dank modularem
            Aufbau und geringem Gewicht. Alle wichtigen Teile sind leicht
            zugänglich und (ab-)waschbar.
          </li>
          <li>
            Falls Sie umräumen, umziehen oder Ihre Luftkernmatratze einfach
            mitnehmen möchten: Kein Problem, denn sie ist
            <span class="highlight">leicht zu transportieren</span>. Das
            Füllmaterial Luft wiegt wenig und das System ist beim Transport
            komplett zerlegbar.
          </li>
          <!-- <li>
            Wir empfehlen die Auflage „Öl-Vilal®-Liquidpad“ als Zusatzkomponente
            einer Dynaglobe®-Luftkernmatratze. Diese
            <span class="highlight">
              Lordosenstütze stützt Ihren empfindlichen Lendenwirbelbereich
              zusätzlich mithilfe von Flüssigkeit
            </span>
            (ähnlich wie bei einem Wasserbett).
          </li>-->
          <li>
            Die Smartphone-App Ortho-Well®
            <span class="highlight">
              verwandelt Ihr Schlafzimmer samt Luftkernmatratze in eine
              intelligente Wellness-Oase </span
            >. Damit können Sie sich jeden Tag etwas Gutes tun und ganz einfach
            entspannen und genießen! Besonders zu empfehlen ist ein
            app-gesteuerter Powernapp im Allgäuer Luftbett.
          </li>
        </OrderedList>
      </CopyBox>
      <CopyBox
        align="full"
        headline="Schlafen wie auf Wolken – "
        headlineBold="Probieren Sie es aus!"
      >
        <p>
          Das Gefühl, auf einem Allgäuer Luftbett zu liegen, lässt sich mit
          Worten nur schwer beschreiben. Viele sprechen von einem Gefühl der
          Geborgenheit, einem Gefühl wie zu schweben oder
          <span class="highlight">wie auf Wolken gebettet</span>
          zu sein. Auf jeden Fall einzigartig und es fühlt sich ganz anders als
          auf anderen Matratzenarten an. Ihrem Bewegungsapparat wird es gut tun.
          Das sollten Sie unbedingt einmal selbst erleben.
        </p>
      </CopyBox>
      <QuoteTeaser headlineBold="Hedwig und Bernhard Z." headline="– Kunden">
        <p>
          "Die Anschaffung dieser beiden Allgäuer Luftbetten war eine der
          wertvollsten, wenn nicht die grandioste Errungenschaft überhaupt im
          Verlauf unserer nun schon 37-jährigen Ehe.“
        </p>
      </QuoteTeaser>
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventBus'
import CopyBox from '@/components/CopyBox.vue'
import OrderedList from '@/components/OrderedList.vue'
import QuoteTeaser from '@/components/QuoteTeaser.vue'

export default {
  components: {
    CopyBox,
    OrderedList,
    QuoteTeaser
  },
  mounted() {
    EventBus.$emit('headline', 'Die Vorteile')
    EventBus.$emit('headlineExt', 'unserer Luftkernmatratzen')
  }
}
</script>

<style lang="scss"></style>
