<template>
  <div class="sidebar">
    <div
      class="sidebar-backdrop"
      @click="closeSidebarPanel"
      v-if="isPanelOpen"
    ></div>
    <transition name="slide">
      <div v-if="isPanelOpen" class="sidebar-panel" id="nav">
        <Burger />
        <nav>
          <ul>
            <li @click="closeSidebarPanel">
              <router-link :to="{ name: 'allgaeuer-luftbett' }"
                >Allgäuer Luftbett</router-link
              >
              <div class="subnav">
                <router-link :to="{ name: 'vorteile' }">Vorteile</router-link>
                <router-link :to="{ name: 'pflege' }">Pflege</router-link>
                <router-link :to="{ name: 'preise' }">Preise</router-link>
                <router-link :to="{ name: 'garantie' }">Garantie</router-link>
                <router-link :to="{ name: 'einbau' }">Einbau</router-link>
              </div>
            </li>
            <li @click="closeSidebarPanel">
              <router-link :to="{ name: 'service' }">Unser Service</router-link>
              <div class="subnav">
                <router-link :to="{ name: 'schlafberatung' }"
                  >Schlafberatung</router-link
                >
                <router-link :to="{ name: 'schlafprofil' }"
                  >Schlafprofil erstellen</router-link
                >
                <router-link :to="{ name: 'probeliegen' }"
                  >Probeliegen</router-link
                >
                <router-link :to="{ name: 'lieferung' }">Lieferung</router-link>
              </div>
            </li>
            <li @click="closeSidebarPanel">
              <router-link :to="{ name: 'faq' }">Häufige Fragen</router-link>
            </li>
            <li @click="closeSidebarPanel">
              <router-link :to="{ name: 'ueber-uns' }">Über Uns</router-link>
              <div class="subnav">
                <router-link :to="{ name: 'herkunftsgeschichte' }"
                  >Herkunftsgeschichte</router-link
                >
                <router-link :to="{ name: 'kundenstimmen' }"
                  >Kundenstimmen</router-link
                >
              </div>
            </li>
            <li @click="closeSidebarPanel">
              <router-link :to="{ name: 'blog' }">Blog</router-link>
            </li>
            <li @click="closeSidebarPanel">
              <router-link :to="{ name: 'kontakt' }">Kontakt</router-link>
            </li>
          </ul>
        </nav>
      </div>
    </transition>
  </div>
</template>

<script>
import Burger from '@/components/menu/BurgerMenu.vue'

export default {
  components: {
    Burger
  },
  computed: {
    isPanelOpen() {
      return this.$store.state.nav.isNavOpen
    }
  },
  methods: {
    closeSidebarPanel() {
      this.$store.commit('TOGGLE_NAV')
    }
  }
}
</script>

<style lang="scss" scoped>
#nav {
  ul {
    list-style: none;
    padding: 0;

    li {
      margin-bottom: 20px;
    }
  }
  a {
    color: $color-white;
    text-decoration: none;
    margin-right: 20px;
    font-size: 24px;
    font-weight: 300;

    &.router-link-exact-active {
      font-weight: bold;
    }
  }
  .subnav {
    margin-top: 10px;
    a {
      display: block;
      padding: 3px 0;
      margin-left: 12px;
      font-size: 17px;
    }
  }
}
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.2s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(-100%);
  transition: all 150ms ease-in 0s;
}

.sidebar-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 998;
  top: 0;
  left: 0;
  cursor: pointer;
}

.sidebar-panel {
  overflow-y: auto;
  box-sizing: border-box;
  background-color: $color-brown;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  z-index: 999;
  padding: 2rem 40px 2rem 40px;
  width: 320px;
}
</style>
