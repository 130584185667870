<template>
  <div>
    <div class="wrapper">
      <CopyBox>
        <p>
          Seit den 60er Jahren hat die Zahl der Wasserbetten in unseren
          Schlafzimmern kontinuierlich zugenommen. Richtig befüllt passen sich
          Wasserbetten dem menschlichen Körper an, so dass sich der Druck
          gleichmäßig verteilt und damit im Vergleich zu herkömmlichen Matratzen
          eine bessere Blutzirkulation ermöglicht wird. Zusätzlich soll die
          Temperatur der für den Betrieb eines Wasserbetts notwendigen Heizung
          Schlafende wärmen und deren Entspannung fördern. Bereits seit mehreren
          Jahrzehnten gibt es – inspiriert von der Wasserbetttechnologie –
          sogenannte Luftkernmatratzen, bei denen ein äußerst stabiler und
          strapazierfähiger Matratzenkern eben nicht mit Wasser, sondern mit
          Luft befüllt wird. Solche Luftkerne sind für ein Körpergewicht bis 240
          Kilogramm geeignet. Hier ein paar der bedeutendsten Unterschiede
          zwischen einer hochwertigen Luftkernmatratze wie der unseren und einem
          Wasserbett:
        </p>
      </CopyBox>
      <CopyBox
        align="right"
        headline="Luftkernmatratze –"
        headlineBold="vergleichsweise ein Leichtgewicht"
      >
        <p>
          Einen großen Einfluss auf das komplette Schlafsystem hat das
          vergleichsweise geringe Gewicht der Luftkernmatratze. Das Füllmaterial
          Luft wiegt fast nichts, sich positiv auf die komplette
          Matratzenhandhabung auswirkt – vom Verschieben, Transport über Wartung
          und Pflege bis hin zu Möglichkeiten, Platz unter der Matratze als
          Stauraum zu nutzen. Ein Wasserbett ist mit etwa 500 bis 600 Litern
          Wasser gefüllt, was ein entsprechendes Gewicht bedeutet. Ein befülltes
          Wasserbett lässt sich somit nicht verschieben und auch bei einer
          gründlichen Reinigung und Pflege der Wassermatratze kann das hohe
          Gewicht der Wasserfüllung für erhebliche körperliche Anstrengung
          sorgen.
        </p>
      </CopyBox>
      <CopyBox
        headline="Wartungsaufwand bei"
        headlineBold="Luftkernmatratze und Wasserbett im Vergleich"
      >
        <p>
          Die Instandhaltung eines Wasserbettes bringt einiges an Aufwand mit
          sich: Zur Erhaltung der Geschmeidigkeit sollte das Vinyl der
          Wassermatratze regelmäßig mit speziellem Pflegemittel gereinigt
          werden. Auch das Wasser im Inneren der Matratze muss regelmäßig mit
          einem Pflegemittel behandelt werden. Wenn sich in der Wassermatratze
          Luftblasen bilden, kann es zu unangenehmen Geräuschen kommen und der
          Liegekomfort kann negativ beeinflusst werden. Deswegen sollte die
          Wassermatratze bei Luftblasenbildung entlüftet werden. Mit alldem
          verglichen ist Reinigung, Wartung und Pflege einer Luftkernmatratze
          viel unkomplizierter. Entlüftet werden muss diese nicht, die Luft im
          Inneren muss nicht gereinigt werden und dank des geringen Gewichts und
          des modularen Aufbaus ist eine Luftkernmatratze relativ leicht
          zugänglich und gründlich zu reinigen. Sie lässt sich einfach
          auseinandernehmen.
        </p>
      </CopyBox>
      <CopyBox
        align="right"
        headline="Folgekosten für"
        headlineBold="Stromverbrauch und Reinigungsmittel"
      >
        <p>
          Im Gegensatz zur Luftkernmatratze muss ein Wasserbett permanent
          beheizt und damit ständig mit Strom versorgt werden. Eine
          Luftkernmatratze muss nicht beheizt werden – Strom ist lediglich zur
          Befüllung des Luftkerns notwendig. Sobald die Luftkernmatratze
          wunschgemäß befüllt ist, kann man die Steuerung von der Stromzufuhr
          trennen und stromfrei schlafen. D.h. bei der Luftkernmatratze dürfte
          der Stromverbrauch wesentlich niedriger als beim Wasserbett ausfallen.
          Ähnlich sieht es mit den laufenden Kosten für Pflegemittel –
          Vinylreiniger und Conditioner benötigt die Luftkernmatratze nicht.
          Zwar gibt es auch hier spezielle Pflegemittel, die man verwenden kann,
          jedoch benötigt man für eine ordentliche Wartung und Pflege – wenn man
          den Jahresverbrauch betrachtet – vergleichsweise wesentlich weniger
          davon.
        </p>
      </CopyBox>
      <CopyBox
        headline="Eine Luftkernmatratze lässt sich"
        headlineBold="schnell punktgenau befüllen und ermöglicht unendlich viele Härtegrade"
      >
        <p>
          Während beim Wasserbett Wasser befüllt oder entnommen werden muss, um
          die Härte bzw. den Druck im Matratzenkern zu verändern, lässt sich
          dieser bei der Luftkernmatratze punktgenau verändern – und das schnell
          und einfach via Knopfdruck. Unsere Luftkernmatratzen verfügen sogar
          über eine ausgereifte Smartphoneanwendung mit mehreren Programmen, wie
          z.B. ein Powernap-Programm, ein Entspannungsprogramm, ein
          Wirbelsäulenstreckprogramm oder ein Programm zur Körpervermessung, das
          einem eine individuell passende Befüllung der Luftkernmatratze
          vorschlägt.
        </p>
      </CopyBox>
      <CopyBox align="right" headline="Fazit">
        <p>
          Wenn man sich näher mit den beiden Schlafsystemen auseinandersetzt,
          kann man nachvollziehen, dass hochwertige Luftkernmatratzen aus einer
          Weiterentwicklung der Wasserbett-Technologie entstanden sind. Während
          einige Grundprinzipien, wie die individuelle, körperformgebende
          Anpassung des Matratzenkerns an den Schlafenden übernommen wurden,
          kommen bei hochwertigen Luftkernmatratzen vor allem bei den Aspekten
          Gewicht, Handhabung, Pflegeaufwand, Strombedarf und komfortabler
          Steuerbarkeit des Matratzendrucks und damit des Härtegrades bzw. der
          Festigkeit der Matratze zusätzliche Vorteile hinzu.
        </p>
      </CopyBox>
      <QuoteTeaser headlineBold="Christian B." headline="– Kunde">
        <p>
          "Wir hatten früher zwei Wasserbetten. … das Liegegefühl auf der
          Luftkernmatratze in Verbindung mit den komfortablen
          Steuerungsmöglichkeiten und dem überschaubaren Pflegeaufwand gefällt
          mir persönlich nochmal deutlich besser."
        </p>
      </QuoteTeaser>
      <div v-if="faqs.length">
        <CopyBox
          align="full"
          headline="Häufige"
          headlineBold="Fragen"
        ></CopyBox>
        <CopyBox align="full">
          <AccordionItem
            v-for="(item, index) in faqs"
            :key="index"
            :title="item.question"
            :text="item.answer.html"
            :number="index"
          ></AccordionItem
        ></CopyBox>
      </div>
      <CtaTeaser
        headline="Noch mehr"
        headlineBold="Fragen?"
        ctaLink="faq"
        ctaText="Zur FAQ Übersicht"
      >
      </CtaTeaser>
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventBus'
import CopyBox from '@/components/CopyBox.vue'
import QuoteTeaser from '@/components/QuoteTeaser.vue'
import CmsService from '@/services/CmsService'
import AccordionItem from '@/components/AccordionItem.vue'
import CtaTeaser from '@/components/CtaTeaser.vue'

export default {
  name: 'FaqComparisonPage',
  data() {
    return {
      faqs: {}
    }
  },
  components: { CopyBox, QuoteTeaser, AccordionItem, CtaTeaser },
  mounted() {
    EventBus.$emit('headline', 'Was unterscheidet eine Luftkernmatratze')
    EventBus.$emit('headlineExt', 'vom Wasserbett?')
  },
  created() {
    CmsService.getFaqs('faq-wasserbett-luftkernmatratze-vergleich').then(
      data => {
        this.faqs = data
      }
    )
  }
}
</script>

<style lang="scss" scoped></style>
