<template>
  <div>
    <div class="wrapper">
      <CopyBox
        align="left"
        headline="Leichte Pflege dank"
        headlineBold="modularem Aufbau"
      >
        <p>
          Bei einem Allgäuer Luftbett ist der Pflegeaufwand äußerst gering. Dank
          durchdachtem, modularem Aufbau sind sämtliche Einzelkomponenten
          <span class="highlight">gut zu reinigen</span>
          und zu pflegen. So sind die Topper dank durchdacht angebrachter
          Reißverschlüsse ganz einfach abnehmbar und auch in haushaltsüblichen
          Waschmaschinen problemlos waschbar. Selbstverständlich sind die Topper
          nach dem Waschen auch wieder einfach anzubringen. Auch der Luftkern im
          Inneren der Matratze ist gut erreichbar – falls Sie ihn ab und zu mal
          reinigen möchten. All das ermöglicht bei der Verwendung unseres
          Luftschlafsystems einen sehr
          <span class="highlight">hohen Hygienestandard</span>. … und das ist
          wichtig, denn unsere Matratzen werden ja u.a. auch als Hotelbetten,
          Ruheliegen in Fitness- und Wellnesseinrichtungen sowie als
          Pflegebetten genutzt. Manche unserer Matratzen sind bereits seit 2003
          im Hotelbereich im Dauereinsatz.
        </p>
        <p>
          <a
            href="https://assets.allgaeuer-luftbett.de/pdf/pflegeanleitung.pdf"
            target="_blank"
          >
            Hier können Sie unsere Pflegeanleitung als PDF-Dokument ansehen und
            herunterladen </a
          >.
        </p>
        <p>
          Falls Sie darüber hinaus noch Fragen zu Reinigung, Pflege oder den
          Materialien haben, zögern Sie nicht uns zu kontaktieren – entweder per
          E-Mail oder Telefon. Wir sind jederzeit gerne für Sie da.
        </p>
      </CopyBox>
      <CopyBox
        align="right"
        headline="Pflege Ihrer"
        headlineBold="Luftkernmatratze"
        ><p>
          In diesem Video sehen Sie Anregungen zur Pflege Ihrer
          Luftkernmatratze.
        </p>
        <VideoPlayer
          source="https://www.youtube.com/embed/EH0tmoKvGR0"
        ></VideoPlayer>
      </CopyBox>
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventBus'
import CopyBox from '@/components/CopyBox.vue'
import VideoPlayer from '@/components/VideoPlayer.vue'

export default {
  components: {
    CopyBox,
    VideoPlayer
  },
  mounted() {
    EventBus.$emit('headline', 'Pflege')
    EventBus.$emit('headlineExt', 'unserer Luftkernmatratzen')
  }
}
</script>

<style lang="scss"></style>
