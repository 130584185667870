<template>
  <div>
    <div class="wrapper">
      <CopyBox headline="Alles begann mit" headlineBold="Rückenschmerzen">
        <p>
          Die Geschichte vom Allgäuer Luftbett beginnt mit mir, Uli Schmid.
          Früher plagten mich über Jahre hinweg Rückenschmerzen. Das lag
          vermutlich an meiner Wirbelsäulenverkrümmung. Und mein Beruf als Koch,
          Gastronom und Hotelier war natürlich auch alles andere als förderlich
          für meine Rückengesundheit. Morgens bin ich oft nur unter großen
          Schmerzen aus dem Bett gekommen. Meine Beschwerden reichten bis hin zu
          herausgesprungenen Wirbeln. Die Ärzte konnten mir kaum Linderung
          verschaffen. Also suchte ich selbst nach Lösungen.
        </p>
      </CopyBox>
      <CopyBox
        align="right"
        headline="Gesund im Schlaf –"
        headlineBold="mit einer Erfindung aus der Schweiz"
      >
        <p>
          Dabei fand ich zunächst Linderung mit einer Schlaflösung aus der
          Schweiz. Es handelte sich dabei um das sogenannte Luft-Wasserbett,
          eine Vorstufe des heutigen Allgäuer Luftbetts. Dieses neue
          Schlafsystem durfte ich drei Wochen lang testen. Schon in den ersten
          Tagen auf dem Schweizer Bett spürte ich einen nie gekannten
          Liegekomfort – ein Gefühl von absoluter Entspannung und Geborgenheit.
          Ganz anders als auf einer herkömmlichen Matratze. Ich fühlte mich
          sofort wohl. Und nach drei Wochen auf diesem Bett war ich nahezu
          beschwerdefrei.
        </p>
      </CopyBox>
      <CopyBox
        headline="Komplette Ausstattung eines Hotels "
        headlineBold="mit der Luftkernmatratze "
      >
        <p>
          Ich war glücklich und begeistert. Und aus dieser Begeisterung heraus
          habe ich das neue Betten-System für unser privates Schlafzimmer
          angeschafft, intensiven Kontakt mit dem Hersteller gepflegt und auch
          in unserem Hotelbetrieb die ersten Zimmer damit ausgestattet. Und das
          kam bei unseren Gästen so gut an, dass wir 2005 unser komplettes Hotel
          mit dem „Öl-Vitalbett“ bzw. der Luftkernmatratze der
          allgäu-schwäbischen Firma Dynaglobe ausgestattet haben. Der Schweizer
          Erfinder hatte die Patentrechte zwischenzeitlich an die Firma
          Dynaglobe verkauft.
        </p>
      </CopyBox>
      <CopyBox
        align="right"
        headline="Problem und Herausforderung für die Hotelbranche: "
        headlineBold="Matratzen"
      >
        <p>
          Sicherlich haben Sie im Fernsehen schon mal Hoteltests gesehen, bei
          denen genauer in und unter die Betten geschaut wird. Nur in wenigen
          Fällen sind die Tester positiv überrascht. Da werden
          Federkernmatratzen mit Schwarzlicht untersucht. Was da sichtbar wird,
          will man vor der nächsten Hotelübernachtung lieber gar nicht wissen.
          Wenn die Matratze dann auch noch durchgelegen ist, findet selbst der
          anspruchsloseste Gast keinen guten Schlaf.
        </p>
      </CopyBox>
      <CopyBox
        headline="Guter Schlaf der Hotelgäste hatte immer"
        headlineBold="oberste Priorität für mich"
      >
        <p>
          Ich hatte ein weit verbreitetes Problem der Hotelbranche erkannt: Es
          wird viel in Raumausstattung, Wellness und Optik investiert. Doch an
          der Voraussetzung für guten, regenerativen und hygienischen Schlaf
          wird meist gespart. Das konnte ich als selbstständiger Hotelier nie
          nachvollziehen, denn schon seit langem bestätigen Umfragen, dass der
          gute Schlaf für den Gast oberste Priorität bei einer Hotelübernachtung
          hat (siehe z. B. Fraunhofer-Institut für Arbeitswirtschaft und
          Organisation, FutureHotel). Den Aspekt „Schlafqualität“ sehe ich nach
          wie vor als riesige Chance und potentiellen Wettbewerbsvorteil für
          clevere Hoteliers.
        </p>
      </CopyBox>
      <CopyBox
        align="right"
        headline="Wie meine Handelsagentur und das"
        headlineBold="„Allgäuer Luftbett“ entstanden"
      >
        <p>
          Sowohl in unserem Betrieb als auch in Partner-Hotels kam das äußerst
          bequeme und leicht zu reinigende „Öl-Vitalbett“ sehr gut an. Immer
          mehr Gäste, die das außergewöhnliche Schlaferlebnis schätzten,
          interessierten sich für eine Eigenanschaffung. Und daraus entstand
          meine Handelsagentur Ulrich Schmid, die nur zwei Produkte vertreibt:
          Das Allgäuer Luftbett und das dazu passende Luftkern-Kopfkissen. Diese
          Lösung bezeichne ich auch gerne als „Maßanzug für die Wirbelsäule“ und
          als „wunderbar-veränderbar“.
        </p>
      </CopyBox>
      <CopyBox
        headline="Luftkern-Schlafsysteme mit"
        headlineBold="einfach auszutauschenden Teilen"
      >
        <p>
          Für mich ist das Allgäuer Luftbett das „Bett fürs Leben“. Es ist
          äußerst langlebig – dank Verwendung hochwertigster Materialien, dem
          hervorragenden Hygienestandard, den Hauptträgern Luft und Öl (keine
          Materialermüdung) und der Tatsache, dass alle Einzelteile austauschbar
          sind. Das wird von der Matratzenbranche im Wettbewerb natürlich nicht
          gerne gesehen, weil Folgekäufe nicht mehr notwendig sind. Probleme wie
          durchgelegene Matratzen oder Materialermüdung wie beim Wasserbett gibt
          es bei diesem Luftkern-Schlafsystem nicht mehr. Und sollten doch
          einmal kleinere Probleme auftauchen, sind wir auch nach dem Kauf
          jederzeit für unsere Kunden da und helfen schnell und unkompliziert.
        </p>
      </CopyBox>
      <CopyBox
        align="right"
        headline="Meine Erfahrungen als Anwender und Hotelier"
        headlineBold="mit dem Allgäuer Luftbett"
      >
        <p>
          Dank meiner Erfahrungen aus der gewerblichen Nutzung wurde das neue
          Schlafsystem der Firma Dynaglobe ständig verbessert und zu dem
          heutigen Luftbett entwickelt, das ich als „Allgäuer Luftbett“
          vermarkte – für mich das mit Abstand flexibelste Schlafsystem seit der
          Erfindung der Matratze. Über die Jahre habe ich ja nicht nur viele
          Gespräche mit Medizinern, Physiotherapeuten, Heilpraktikern und
          Experten in Sachen Schlaf und Rückgesundheit, sondern auch eine große
          Anzahl an ausführlichen Beratungsgesprächen mit Interessenten und
          späteren Kunden geführt. Diese schildern mir ihre Schlafprobleme ganz
          offen und vertraulich.
        </p>
      </CopyBox>
      <CopyBox
        headline="Das Geheimnis von"
        headlineBold="gesundem und erholsamem Schlaf"
      >
        <p>
          Die aktuelle Tendenz zum Kauf von harten Matratzen ist alles andere
          als förderlich für die Nachtruhe. Speziell Seitenschläfer haben
          Probleme mit Druckstellen und häufigem Drehen, was den Durchschlaf
          stört. Der wichtigste Schlüssel für erholsamen Schlaf ist die
          nächtliche Dehnung der Wirbelsäule. Dies funktioniert nur durch
          optimale Gewichtsverteilung – wenn Entlastung und Unterstützung der
          einzelnen Körperteile gleich gut sind. Die positiven Folgen: erhöhte
          Blutzirkulation, freies vegetatives Nervensystem,
          Muskulatur-Entspannung und verbesserte Flüssigkeitsaufnahme in die
          Bandscheiben (welche nur nachts erfolgt). Aus meiner Sicht erfüllt
          unser luftgesteuertes Schlafsystem diese Anforderungen derzeit am
          besten.
        </p>
      </CopyBox>
      <CopyBox
        align="right"
        headline="Gesunder Schlaf für"
        headlineBold="Allergiker und Schmerzpatienten"
      >
        <p>
          Unser Schlafsystem der Firma Dynaglobe vereint die Vorteile aus
          Wasserbett, Luftbett und konventioneller Matratze. Und obendrauf ist
          es auch noch leicht zu pflegen, leicht zu transportieren und äußerst
          langlebig. Und diese Kombination – in Verbindung mit einer sehr guten
          Beratung – steckt hinter meinem Konzept „Allgäuer Luftbett“. Mit über
          1.000 verkauften Betten und ebenso vielen zufriedenen Kunden wissen
          wir, worauf zu achten ist. Wir stellen gerne auch Ihnen und Ihren
          Lieben „Ihr Bett fürs Leben" zusammen, den veränderbaren Maßanzug für
          Ihre Wirbelsäule.
        </p>
      </CopyBox>
      <CopyBox
        headline="Wünschen auch Sie sich"
        headlineBold="erholsamen, gesunden Schlaf?"
      >
        <p>
          Bei Fragen zum Allgäuer Luftbett und zu gesundem und erholsamem Schlaf
          bin ich gerne jederzeit für Sie da. Investieren Sie einfach mal Ihre
          Zeit in einen unverbindlichen und kostenfreien Beratungstermin mit
          Probeliegen und Vermessung in meinen Räumen oder fordern Sie innerhalb
          des Allgäus mein „Luftbett-Mobil“ fürs Probeliegen bei Ihnen zuhause
          an. Nutzen Sie meine langjährige Erfahrung und mein Fachwissen, um zu
          erfahren, was für Ihren individuellen, regenerativen und erholsamen
          Schlaf wirklich wichtig ist.
        </p>
      </CopyBox>
      <QuoteTeaser
        headlineBold="„Jeder Tag"
        headline="kann gewonnene oder verlorene Lebensqualität bedeuten!“"
      ></QuoteTeaser>
      <CopyBox
        align="right"
        headline="Endlich schmerzfrei"
        headlineBold="mit dem Allgäuer Luftbett!"
      >
        <p>
          Das ist mir unter Rückenschmerzen klar geworden. Ich selbst erlebe
          dank gutem und erholsamem Schlaf seit mehreren Jahren nachhaltig eine
          bessere Lebensqualität. Endlich schmerzfrei! Dafür bin ich sehr
          dankbar. Meine Erfahrung gebe ich gerne an Sie und Ihre Lieben weiter.
          Ich freue mich jedes Mal, wenn ich jemanden mit einem Allgäuer
          Luftbett glücklich machen kann. Und genau diese Haltung lebt auch
          meine Tochter und Juniorpartnerin Claudia Schmid.
        </p>
        <p>
          Ihr Profi für gesunden Schlaf aus dem Allgäu, Uli Schmid
        </p>
      </CopyBox>
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventBus'
import CopyBox from '@/components/CopyBox.vue'
import QuoteTeaser from '@/components/QuoteTeaser.vue'

export default {
  components: {
    CopyBox,
    QuoteTeaser
  },
  mounted() {
    EventBus.$emit('headline', 'Herkunftsgeschichte')
    EventBus.$emit('headlineExt', 'des Allgäuer Luftbetts ')
  }
}
</script>

<style lang="scss"></style>
