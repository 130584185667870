const getCookie = name => {
  var cookieArr = document.cookie.split(';')

  for (var i = 0; i < cookieArr.length; i++) {
    var cookiePair = cookieArr[i].split('=')

    if (name == cookiePair[0].trim()) {
      return decodeURIComponent(cookiePair[1])
    }
  }

  return null
}

const checkConsent = cat => {
  let CookieValue = getCookie('cookiefirst-consent')

  const consents = JSON.parse(CookieValue)

  if (consents) {
    if (cat == 'necessary') {
      return consents.necessary
    }

    if (cat == 'functional') {
      return consents.functional
    }

    if (cat == 'performance') {
      return consents.performance
    }

    if (cat == 'advertising') {
      return consents.advertising
    }
  }

  return false
}

export default {
  checkConsent
}
