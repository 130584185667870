import axios from 'axios'

const apiClient = axios.create({
  baseURL: 'https://www.allgaeuer-luftbett.de/api',
  withCredentials: false,
  header: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

export default {
  postContact(formData) {
    return apiClient.post('/contact/', formData)
  }
}
