<template>
  <div class="quote-teaser">
    <span class="headline">
      <span class="highlight">{{ headlineBold }}</span>
      {{ headline }}
    </span>
    <slot></slot>
    <CTA text="Mehr Kundenstimmen" link="kundenstimmen"></CTA>
  </div>
</template>

<script>
import CTA from '@/components/CTA.vue'

export default {
  name: 'QuoteTeaser',
  components: {
    CTA
  },
  props: {
    headline: {
      type: String
    },
    headlineBold: {
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
.quote-teaser {
  width: 100%;
  background-color: $color-lighter-brown;
  text-align: center;
  padding: 50px 15%;
  margin: 60px 0;
  box-sizing: border-box;

  p {
    font-size: 20px;
    line-height: 1.5em;
    color: $color-brown;
  }
}
.headline {
  font-weight: 300;
  color: $color-brown;
  font-size: 30px;
  margin-top: 0;
  margin-bottom: 30px;
  padding: 0;
}

@include bp-small {
  .quote-teaser {
    padding: 20px 7%;
    p {
      font-size: 16px;
    }
  }
  .headline {
    font-size: 25px;
  }
}
</style>
