<template>
  <div class="wrapper blogpost">
    <div class="postDetails">Beitrag vom {{ post.datum_de }}</div>
    <img
      :src="
        'https://www.allgaeuer-luftbett.de/api/cnews/_images/posts/' + post.bild
      "
      class="postImage"
      v-if="post.bild"
    />
    <span v-html="post.text"></span>
    <div class="backlink">
      <CTA text="zurück zur Übersicht" link="blog"></CTA>
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventBus'
import CNewsService from '@/services/CNewsService'
import CTA from '@/components/CTA.vue'

export default {
  components: {
    CTA
  },
  data() {
    return {
      post: []
    }
  },
  created() {
    CNewsService.getPost(this.$route.params.postid).then(response => {
      this.post = response.data
      EventBus.$emit('headline', this.post.headline)
      EventBus.$emit('headlineExt', '')
    })
    /* .catch(error => {
        console.log('Axios error: ' + error.response)
      }) */
  }
}
</script>

<style lang="scss" scoped>
.blogpost {
  font-size: 18px;
  line-height: 1.5em;

  @extend %clearfix;
}
.postDetails {
  font-weight: 600;
  margin-bottom: 20px;
}
.postImage {
  float: right;
  width: 50%;
  margin-left: 35px;
  margin-bottom: 35px;
}
.backlink {
  margin-top: 35px;
  text-align: center;
}
</style>
