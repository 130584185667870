<template>
  <div class="cta-teaser">
    <div class="wrapper">
      <h2>
        {{ headline }}
        <span class="highlight">{{ headlineBold }}</span>
      </h2>
      <slot></slot>
      <div class="cta-container">
        <CTA :link="ctaLink" :text="ctaText" inverted />
      </div>
    </div>
  </div>
</template>

<script>
import CTA from '@/components/CTA.vue'

export default {
  name: 'CTA-Teaser',
  props: {
    headline: {
      type: String
    },
    headlineBold: {
      type: String
    },
    ctaLink: String,
    ctaText: String
  },
  components: {
    CTA
  }
}
</script>

<style lang="scss">
.cta-teaser {
  width: 100%;
  background-color: $color-brown;
  text-align: center;
  padding: 60px 20px;
  margin: 60px 0;
  box-sizing: border-box;

  @include bp-small {
    padding: 40px 7.5%;
  }

  p {
    font-size: 18px;
    line-height: 1.5em;
    color: white;

    @include bp-small {
      font-size: 16px;
    }
  }
  h2 {
    font-weight: 300;
    color: white;
    font-size: 30px;
    margin-top: 0;
    margin-bottom: 30px;
    padding: 0;

    @include bp-small {
      font-size: 25px;
    }
  }
  a {
    color: white;
  }
}
.cta-container {
  margin-top: 50px;
}
</style>
