import { GraphQLClient } from 'graphql-request'
import getInfoboxTeaserQuery from '../storage/getInfoboxTeaser'
import getFaqBlocksQuery from '../storage/getFaqBlocks'
import getFaqsQuery from '../storage/getFaqs'
const endpoint = process.env.VUE_APP_GRAPHCMS_ENDPOINT
const token = process.env.VUE_APP_GRAPHCMS_TOKEN

const graphQLClient = new GraphQLClient(endpoint, {
  headers: {
    authorization: 'Bearer ' + token
  }
})

// queries
const getInfoboxTeaser = async () => {
  try {
    const vars = { type: 'Infobox' }
    const query = await graphQLClient.request(
      getInfoboxTeaserQuery.query(),
      vars
    )
    return query.teasers
  } catch (error) {
    return error
  }
}

const getFaqBlocks = async () => {
  try {
    const query = await graphQLClient.request(getFaqBlocksQuery.query())
    return query.faqBlocks
  } catch (error) {
    return error
  }
}

const getFaqs = async blockSlug => {
  try {
    const vars = { slug: blockSlug }
    const query = await graphQLClient.request(getFaqsQuery.query(), vars)
    return query.faqs
  } catch (error) {
    return error
  }
}

export default {
  getInfoboxTeaser,
  getFaqBlocks,
  getFaqs
}
