<template>
  <div>
    <div class="wrapper">
      <CopyBox
        headline="Bestens informiert über die Themen"
        headlineBold="Schlaf und Luftbett"
      ></CopyBox>
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventBus'
import CopyBox from '@/components/CopyBox.vue'

export default {
  components: {
    CopyBox
  },
  mounted() {
    EventBus.$emit('headline', 'Allgäuer Luftbett:')
    EventBus.$emit('headlineExt', 'Unser Newsletter')
  }
}
</script>

<style lang="scss"></style>
