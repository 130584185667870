<template>
  <div class="video-container">
    <iframe
      :src="source"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script>
export default {
  name: 'VideoPlayer',
  props: {
    source: {
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 0;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@include bp-small {
  a.button,
  a:visited.button,
  a:active.button {
    padding: 10px 20px;
    font-size: 16px;
    text-align: center;
  }
}
</style>
