<template>
  <div>
    <div class="wrapper">
      <CopyBox
        headline="Wichtige Fragen werden in"
        headlineBold="persönlichen Gesprächen geklärt"
      >
        <p>
          Zu unseren Kunden zählen viele Menschen mit Schmerzen am
          Bewegungsapparat, aber auch immer mehr junge, vollkommen gesunde
          Menschen und Sportler – Leute, die Entspannung, Erholung, Wellness und
          ein außergewöhnlich schönes Liegeerlebnis zu schätzen wissen. Da unser
          Produkt neu und erklärungsbedürftig war, haben wir mit fast all
          unseren bisherigen Kunden ein persönliches Gespräch geführt und bei
          der Zusammenstellung des persönlichen „Betts fürs Leben“ geholfen.
        </p>
        <p>
          Die Kundenbetreuung endet bei uns nicht mit dem Verkauf sondern reicht
          in den meisten Fällen bis zu einer telefonischen Begleitung während
          der Eingewöhnungsphase mit dem neuen Schlafsystem. Nachfolgend finden
          Sie eine Auswahl der Fragen, die uns in der Beratung oft gestellt
          werden, klicken Sie einfach auf eine Frage um die Antwort zu erfahren:
        </p>
      </CopyBox>
      <CopyBox
        align="full"
        headline="Die häufigsten"
        headlineBold="Fragen und Antworten"
      ></CopyBox>
      <CardItems>
        <div v-for="(data, index) in faqBlocks" :key="index">
          <div>
            <h3>{{ data.headline }}</h3>
            <p>
              {{ data.description }}
            </p>
            <CTA
              external
              :link="'/' + data.slug"
              :text="data.headline + ' FAQs'"
            />
          </div>
        </div>
      </CardItems>
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventBus'
import CopyBox from '@/components/CopyBox.vue'
import CmsService from '@/services/CmsService'
import CardItems from '@/components/CardItems.vue'
import CTA from '@/components/CTA.vue'

export default {
  name: 'FAQ',
  data() {
    return {
      faqBlocks: {}
    }
  },
  components: {
    CopyBox,
    CardItems,
    CTA
  },
  mounted() {
    EventBus.$emit('headline', 'Luftkernmatratzen:')
    EventBus.$emit('headlineExt', 'FAQ')
  },
  created() {
    CmsService.getFaqBlocks().then(data => {
      this.faqBlocks = data
    })
  }
}
</script>

<style lang="scss" scoped></style>
