<template>
  <div class="cards">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'CardItems'
}
</script>

<style lang="scss" scoped>
.cards {
  display: flex;
  flex-flow: row wrap;
  margin: 20px 0;

  div {
    box-sizing: border-box;
    flex: 0 0 50%;
    margin-bottom: 20px;
    border-radius: 10px;

    div {
      height: 100%;
      padding: 20px;
      background-color: $color-lighter-brown;
    }
  }

  div:nth-child(odd) {
    padding-right: 15px;
  }
  div:nth-child(even) {
    padding-left: 15px;
  }
}

@include bp-small {
  .cards {
    display: block;

    div:nth-child(odd) {
      padding-right: 0;
    }
    div:nth-child(even) {
      padding-left: 0;
    }
  }
}
</style>
