<template>
  <div class="formItem">
    <input
      type="checkbox"
      :id="name"
      @change="updateValue"
      v-bind="$attrs"
      v-on="listeners"
      :checked="value"
    />
    <label :for="name" v-if="label">{{ label }}</label>
    <slot></slot>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: ['value', 'name', 'label'],
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        change: this.updateValue
      }
    }
  },
  methods: {
    updateValue(event) {
      this.$emit('input', event.target.checked)
    }
  }
}
</script>

<style lang="scss" scoped>
input[type='checkbox'] {
  display: none;
}
input[type='checkbox'] + label::before {
  width: 15px;
  height: 15px;
  border: 1px solid $color-black;
  background-color: #fff;
  display: block;
  content: '';
  float: left;
  margin-right: 5px;
}
input[type='checkbox']:checked + label::before {
  box-shadow: inset 0px 0px 0px 4px #fff;
  background-color: $color-brown;
}

label {
  display: block;
  font-size: 14px;
  color: $color-brown;
  margin-bottom: 10px;
}
.formItem {
  margin-bottom: 25px;
}
.active {
  border-bottom-color: $color-brown;
}
.error {
  input + label::before {
    border-color: $color-red;
  }
}
</style>
