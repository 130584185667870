<template>
  <div>
    <div class="wrapper">
      <CopyBox
        align="full"
        headline="Einmal wie auf Wolken schweben –"
        headlineBold=" im Allgäuer Luftbett!"
      >
        Das Liegegefühl auf einer Luftkernmatratze und seine wohltuende Wirkung
        auf Ihre Wirbelsäule sind mit Worten schwer zu beschreiben. Sie sollten
        es einfach mal selbst erleben! Zum Probeliegen in einem Allgäuer
        Luftbett bieten sich Ihnen verschiedene Möglichkeiten:
      </CopyBox>
      <CopyBox align="full">
        <Cards>
          <div>
            <div>
              <h3>Vor Ort</h3>
              Schauen Sie nach vorheriger Terminvereinbarung in unserem
              <a href="https://g.page/allgaeuerluftbett?share" target="_blank"
                >Büro in Sulzberg-Öschle</a
              >
              bei Kempten vorbei.<br /><br />
              <span class="highlight">Tipp</span>: Kombinieren Sie Ihren
              Beratungstermin mit einem Spaziergang um den Öschle See direkt vor
              unserer Haustüre! Genießen Sie die abwechslungsreiche Flora und
              Fauna des Landschaftsschutzgebietes und die märchenhafte
              Bergkulisse. Der Rundweg um den Sulzberger See ist 3km lang und
              dauert etwa eine Stunde.
            </div>
          </div>
          <div>
            <div>
              <h3>Telefonische Beratung</h3>
              Auch falls Sie weiter weg wohnen sollten ist das gar kein Problem!
              <br /><br />
              Wir beraten Sie auch ausführlich und individuell per Telefon. Auch
              eine Videoberatung via Zoom ist möglich. Diese Formen der Beratung
              nutzen wir übrigens auch ganz oft, wenn bei Kunden Fragen zu
              unserer Luftkernmatratze auftauchen. Denn für uns endet die
              Kundenbeziehung nicht mit dem Kauf. ;-)
            </div>
          </div>
          <div>
            <div>
              <h3>In Hotels & Gastbetrieben</h3>
              Auch in einigen Hotels haben Sie die Möglichkeit, eine
              Übernachtung auf unserem Luftschlafsystem zu buchen und unsere
              Luftkernmatratze in aller Ruhe zu testen.<br /><br />

              Im Allgäu ist das z. B. im
              <a href="https://www.waldhorn-kempten.de" target="_blank"
                >Hotel Waldhorn</a
              >
              in Kempten möglich. Wichtig ist aber, dass Sie bei der der Buchung
              nach explizit nach „Luftbett“ fragen, den dieser Gastbetrieb
              bietet auch Zimmer mit herkömmlichen Matratzen an.

              <br /><br />Weitere Gastbetriebe, die mit unserer Luftkernmatratze
              ausgestattet sind, nennen wir Ihnen gerne auf Anfrage.
            </div>
          </div>
          <div>
            <div>
              <h3>Im Allgäu-Urlaub</h3>
              Wie wäre es, wenn Sie das Probeliegen unserer Luftkernmatratze
              gleich mit einem Urlaub im Allgäu verbinden?
              <br /><br />
              Wenn Sie auf der Suche nach einer schönen
              <a href="https://facebook.com/muehle11/" target="_blank"
                >Ferienwohnung in traumhafter Lage mit Bergblick und Allgäuer
                Luftbett</a
              >
              sind, dann empfehlen wir Ihnen das Gästehaus Mühle11 in der Nähe
              von Sonthofen.
              <br /><br />
              Ein Blick auf die Mühle11-Website lohnt sich in jedem Fall. Das
              neu errichtete Gästehaus unter der freundlichen Leitung von
              Familie Heßmann ist perfekt für einen Allgäu-Urlaub wie im
              Bilderbuch.
            </div>
          </div>
          <div>
            <div>
              <h3>Auf Messen</h3>
              Der Markt ist dort wo man sich trifft und für Viele ist ein
              persönliches Treffen auch im Online-Zeitalter durch nichts zu
              ersetzen.
              <br /><br />
              Aus diesem Grund besuchen wir auch immer wieder ausgewählte
              Verbrauchermessen im Allgäu. Auch dort können Sie uns aufsuchen
              und das Allgäuer Luftbett Probeliegen – zur Vorbereitung einer
              nachfolgenden individuellen Schlafberatung.
              <br /><br />
              Nähere Infos zu unseren Messebesuchen finden Sie sowohl in unserem
              Blog als auch auf unserer
              <a
                href="https://www.facebook.com/allgaeuerluftbett/"
                target="_blank"
                >Facebookseite</a
              >
            </div>
          </div>
        </Cards>
      </CopyBox>
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventBus'
import CopyBox from '@/components/CopyBox.vue'
import Cards from '@/components/CardItems.vue'

export default {
  components: {
    CopyBox,
    Cards
  },
  mounted() {
    EventBus.$emit('headline', 'Luftkernmatratze')
    EventBus.$emit('headlineExt', 'probeliegen')
  }
}
</script>

<style lang="scss"></style>
