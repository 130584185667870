<template>
  <div class="infobox" v-if="teaserData.length">
    <div v-html="teaserData[0].text.html"></div>
  </div>
</template>

<script>
import CmsService from '@/services/CmsService'

export default {
  name: 'InfoBox',
  data() {
    return {
      teaserData: {}
    }
  },
  created() {
    CmsService.getInfoboxTeaser().then(data => {
      this.teaserData = data
    })
  }
}
</script>

<style lang="scss">
.infobox {
  position: absolute;
  bottom: 25%;
  right: 0;
  width: 40%;
  padding: 1em;
  background-color: $color-neon-yellow;
  box-shadow: 0 0 10px $color-dark-grey;
  box-sizing: border-box;
  outline: none;
  font-size: 1.3em;

  p {
    margin: 0.2em;
    padding: 0;
  }
  a {
    text-decoration: none;
  }

  @include bp-medium {
    position: relative;
    top: 0;
    left: 0;
    bottom: auto;
    width: 100%;
    font-size: 0.9em;
    text-align: center;
    background-color: $color-neon-yellow;
    box-shadow: none;
  }
}
</style>
