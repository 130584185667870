<template>
  <div>
    <div class="wrapper">
      <CopyBox
        align="left"
        headline="Ihre Luftkernmatratze ist in nur Minuten"
        headlineBold="leicht und bequem aufgebaut"
      >
        Schnell und einfach auf- und eingebaut – ob nach dem Kauf oder wenn Sie
        Ihr Bett einmal transportieren müssen: In etwa 15 Minuten ist Ihr
        zusammengebautes und mit Luft befülltes Allgäuer Luftbett in Ihr
        bestehendes Bettgestell
        <span class="highlight">eingebaut und betriebsbereit</span>
        angeschlossen. Mit Bekanntgabe der Lieferwoche erhalten Sie von uns den
        Link zu Aufbauvideos per E-Mail. Falls Sie einen Bettrahmen in einer
        Sondergröße haben sollten: Gar kein Problem. Neben den marktüblichen
        Größen ist unsere Luftkernmatratze auf Anfrage
        <span class="highlight">auch in Sondergrößen</span> erhältlich. Der
        Aufbau erfolgt ohne großen Aufwand. Ihre neue Luftkernmatratze ist
        sofort einsatzbereit.
      </CopyBox>
      <CopyBox
        align="right"
        headline="Einbau mit kostenloser, telefonischer"
        headlineBold="Anleitung vom Profi"
      >
        Während der Eingewöhnungsphase begleiten wir Sie
        <span class="highlight">telefonisch (kostenlos!)</span>. Das schließt
        auch den Aufbau mit ein. Sie rufen uns bei Lieferung einfach kurz an.
        Mit unseren Hinweisen via Telefon ist der
        <span class="highlight">Einbau ins Bettgestell</span> sowie das
        Einstellen Ihrer Luftkernmatratze auch für Sie ein Kinderspiel.
        Versprochen!
      </CopyBox>
      <CopyBox
        align="left"
        headline="Hilfe beim Einbau Ihrer"
        headlineBold="Luftkernmatratze vor Ort"
      >
        Oder möchten Sie, dass wir Ihr Luftbett für Sie aufbauen? Falls
        erforderlich, kommen wir allgäuweit auch gerne zu Ihnen und
        <span class="highlight">helfen Ihnen vor Ort beim Einbau</span>
        Ihrer Luftkernmatratze bzw. Ihres Luftschlafsystems. Für Anfahrt und
        Einbau würden Zusatzkosten anfallen.
      </CopyBox>
      <CopyBox align="right" headline="Anschließen der" headlineBold="Steuerung"
        ><p>
          In diesem Video erfahren Sie, wie Sie die Steuerung richtig mit der
          Luftkernmatratze verbinden.
        </p>
        <VideoPlayer
          source="https://www.youtube.com/embed/mWkx3rx0jFk"
        ></VideoPlayer>
      </CopyBox>
      <QuoteTeaser headlineBold="Georgette A." headline="– Kundin">
        <p>"Der Aufbau sowie die Einstellungen waren ganz einfach.“</p>
      </QuoteTeaser>
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventBus'
import CopyBox from '@/components/CopyBox.vue'
import QuoteTeaser from '@/components/QuoteTeaser.vue'
import VideoPlayer from '@/components/VideoPlayer.vue'

export default {
  components: {
    CopyBox,
    QuoteTeaser,
    VideoPlayer
  },
  mounted() {
    EventBus.$emit('headline', 'Der Einbau')
    EventBus.$emit('headlineExt', 'unserer Luftkernmatratze')
  }
}
</script>

<style lang="scss"></style>
