import { render, staticRenderFns } from "./QuoteTeaser.vue?vue&type=template&id=074d1dfb&scoped=true&"
import script from "./QuoteTeaser.vue?vue&type=script&lang=js&"
export * from "./QuoteTeaser.vue?vue&type=script&lang=js&"
import style0 from "./QuoteTeaser.vue?vue&type=style&index=0&id=074d1dfb&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "074d1dfb",
  null
  
)

export default component.exports