<template>
  <div>
    <div class="wrapper">
      <CopyBox
        headline="Preise nach"
        headlineBold="individueller Zusammenstellung"
        align="left"
      >
        Modular aufgebaut: Unser Schlafsystem aus dem Hause Dynaglobe® wird nach
        Ihrem
        <span class="highlight">individuellen Bedarf</span> zusammengestellt.
        Der genaue Preis hängt also von Ihren Wünschen und Bedürfnissen ab –
        unser Ziel ist es, Ihr „Bett fürs Leben“ für Sie zusammenzustellen, das
        Sie jeden Tag aufs Neue erfreut. Doch sicherlich möchten Sie hier schon
        mal wissen, was genau eine Luftkernmatratze bzw. ein Allgäuer Luftbett
        kostet, um die Höhe der
        <span class="hightlight">Investition in Ihre Gesundheit</span> und
        erholsameren Schlaf besser einschätzen können.
      </CopyBox>
      <CopyBox
        headline="Kosten von"
        headlineBold="17 bis 45 Cent pro Nacht"
        align="right"
      >
        Je nach Ausstattung sprechen wir bei einer Beispielgröße von 90 x 200 cm
        von einem Preis, der sich
        <span class="highlight">zwischen 917,- und 2.473,- Euro</span> bewegt.
        Das entspricht bei einer angenommenen Nutzungsdauer von 15 Jahren etwa
        <span class="highlight">17 bis 45 Cent pro Nacht</span>. Vergleichen Sie
        das mit dem Preis-Leistungsverhältnis anderer Schlafsysteme und
        Matratzenarten. Sie werden feststellen: Bei unserem „Bett fürs Leben“
        <span class="highlight">stimmt das Preis-Leistungsverhältnis</span>.
        Eine Investition, die sich garantiert für Sie lohnt! ;-) Wie viel ist
        Ihnen besserer Schlaf wert?
      </CopyBox>
      <CopyBox align="right">
        <p>
          <CTA
            link="schlafprofil"
            text="Schlafprofil erstellen und genauen Preis anfragen"
          />
        </p>
      </CopyBox>
      <QuoteTeaser headlineBold="Bernhard Z." headline="– Kunde">
        <p>
          "Wenn ich allerdings die zuvor unnützen Geldausgaben für all die über
          Jahrzehnte erprobten Matratzensysteme anderer Hersteller addiere, dann
          erscheinen uns die einmaligen Anschaffungskosten Ihres
          Matratzensystems der Allgäuer Luftbetten geradezu als Peanuts.“
        </p>
      </QuoteTeaser>
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventBus'
import CopyBox from '@/components/CopyBox.vue'
import CTA from '@/components/CTA.vue'
import QuoteTeaser from '@/components/QuoteTeaser.vue'

export default {
  components: {
    CopyBox,
    CTA,
    QuoteTeaser
  },
  mounted() {
    EventBus.$emit('headline', 'Was kostet')
    EventBus.$emit('headlineExt', 'ein Allgäuer Luftbett?')
  }
}
</script>

<style lang="scss"></style>
