<template>
  <div>
    <div class="wrapper">
      <CopyBox
        align="full"
        headline="Häufige Produktfragen"
        headlineBold="zum Allgäuer Luftbett bzw. zur
          Dynaglobe-Luftkernmatratze"
      >
      </CopyBox>
      <CopyBox align="full">
        <AccordionItem
          v-for="(item, index) in faqs"
          :key="index"
          :title="item.question"
          :text="item.answer.html"
          :number="index"
        ></AccordionItem
      ></CopyBox>
      <CtaTeaser
        headline="Noch mehr"
        headlineBold="Fragen?"
        ctaLink="faq"
        ctaText="Zur FAQ Übersicht"
      >
      </CtaTeaser>
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventBus'
import CopyBox from '@/components/CopyBox.vue'
import AccordionItem from '@/components/AccordionItem.vue'
import CmsService from '@/services/CmsService'
import CtaTeaser from '@/components/CtaTeaser.vue'

export default {
  name: 'FaqProductPage',
  data() {
    return {
      faqs: {}
    }
  },
  components: {
    CopyBox,
    AccordionItem,
    CtaTeaser
  },
  mounted() {
    EventBus.$emit('headline', 'Fragen zum Produkt')
  },
  created() {
    CmsService.getFaqs('faq-produkt').then(data => {
      this.faqs = data
    })
  }
}
</script>

<style lang="scss" scoped></style>
