<template>
  <div>
    <div class="wrapper">
      <CopyBox
        headline="Diensteanbieter gem."
        headlineBold="§ 5 Telemediengesetz (TMG)"
      >
        <p>Handelsagentur Uli Schmid</p>
        <p>Ulrich Schmid<br />Felchenweg 4<br />87477 Sulzberg</p>
        <p>+49 (0)8376 8592 <br />E-Mail gute-nacht@ulrich-schmid.de</p>
        <p>USt-ID DE221503664</p>
      </CopyBox>
      <CopyBox align="right" headline="Layout" headlineBold="& Realisierung">
        <p>Commonway Interaction,<br />Hamburg</p>
        <CTA
          external
          target="_blank"
          link="https://www.commonway.de"
          text="commonway.de"
        ></CTA>
      </CopyBox>
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventBus'
import CopyBox from '@/components/CopyBox.vue'
import CTA from '@/components/CTA.vue'

export default {
  components: {
    CopyBox,
    CTA
  },
  mounted() {
    EventBus.$emit('headline', 'Impressum')
    EventBus.$emit('headlineExt', 'Rechtliche Pflichtangaben')
  }
}
</script>

<style lang="scss"></style>
