<template>
  <a href="tel:+4983768592" class="callbutton">
    Jetzt anrufen
  </a>
</template>

<script>
export default {
  name: 'CallButton'
}
</script>

<style lang="scss" scoped>
.callbutton {
  @include bp-small {
    position: fixed;
    z-index: 997;
    display: flex;
    align-items: center;
    bottom: 7.5%;
    right: 7.5%;
    width: 80px;
    height: 80px;
    padding: 1em;
    background-color: $color-dark-green;
    color: $color-white;
    text-align: center;
    box-shadow: 0 0 10px $color-dark-grey;
    box-sizing: border-box;
    border: 2px solid;
    border-radius: 50px;
    outline: none;
    font-size: 0.8em;
    font-weight: 800;
    text-decoration: none;
    cursor: pointer;

    a {
      text-decoration: none;
      color: $color-white;
    }
  }

  display: none;
}
</style>
