import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/HomePage.vue'
import AllgaeuerLuftbett from '@/views/AllgaeuerLuftbettPage.vue'
import Vorteile from '@/views/VorteilePage.vue'
import Pflege from '@/views/PflegePage.vue'
import Preise from '@/views/PreisePage.vue'
import Garantie from '@/views/GarantiePage.vue'
import Einbau from '@/views/EinbauPage.vue'
import Service from '@/views/ServicePage.vue'
import FAQ from '@/views/FaqPage.vue'
import FaqProduct from '@/views/FaqProductPage.vue'
import FaqPrice from '@/views/FaqPricePage.vue'
import FaqMaintenance from '@/views/FaqMaintenancePage.vue'
import FaqTesting from '@/views/FaqTestingPage.vue'
import FaqComparison from '@/views/FaqComparisonPage.vue'
import FaqDelivery from '@/views/FaqDeliveryPage.vue'
import Blog from '@/views/BlogPage.vue'
import Blogpost from '@/views/BlogpostPage.vue'
import Reference from '@/views/ReferencePage.vue'
import Kontakt from '@/views/KontaktPage.vue'
import UeberUns from '@/views/UeberUnsPage.vue'
import Schlafberatung from '@/views/SchlafberatungPage.vue'
import Schlafprofil from '@/views/SchlafprofilPage.vue'
import Probeliegen from '@/views/ProbeliegenPage.vue'
import Lieferung from '@/views/LieferungPage.vue'
import Herkunftsgeschichte from '@/views/HerkunftsgeschichtePage.vue'
import Newsletter from '@/views/NewsletterPage.vue'
import Kundenstimmen from '@/views/KundenstimmenPage.vue'
import Impressum from '@/views/ImpressumPage.vue'
import Datenschutz from '@/views/DatenschutzPage.vue'
import NotFound from '@/views/NotFoundPage.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        title: 'Allgäuer Luftbett: Luftkernmatratze für gesunden Schlaf',
        description:
          'Allgäuer Luftbett: Luftkernmatratze für gesunden, ergonomischen Schlaf, höchsten Schlafkomfort: Dynaglobe® Luftschlafsysteme: Hier erhalten Sie alle Infos.'
      }
    },
    {
      path: '/luftkernmatratze',
      name: 'allgaeuer-luftbett',
      component: AllgaeuerLuftbett,
      meta: {
        subnav: 'luftbett',
        title: 'Allgäuer Luftbett: Schlafsystem für ergonomischen Schlaf',
        description:
          'Allgäuer Luftbett: Schlafsystem für ergonomischen Schlaf: individuell einstellbare und maßgeschneiderte Luftkernmatratze für die Entlastung der Wirbelsäule'
      }
    },
    {
      path: '/luftkernmatratze-vorteile',
      name: 'vorteile',
      component: Vorteile,
      meta: {
        subnav: 'luftbett',
        title: 'Vorteile von Luftkernmatratzen: Allgäuer Luftbett',
        description:
          'Vorteile von Luftkernmatratzen: individuell verstellbare Härtegrade, 100%ige Körperanpassung, einzigartiges Liegegefühl, leichte Reinigung: Allgäuer Luftbett'
      }
    },
    {
      path: '/luftkernmatratze-pflege',
      name: 'pflege',
      component: Pflege,
      meta: {
        subnav: 'luftbett',
        title: 'Pflege, Reinigung von Luftkernmatratzen: Allgäuer Luftbett',
        description:
          'Pflege und Reinigung von Luftkernmatratzen: hoher Hygienestandard durch modularen Aufbau, abnehmbare und waschbare Bezüge und Topper: Allgäuer Luftbett'
      }
    },
    {
      path: '/luftkernmatratze-preis',
      name: 'preise',
      component: Preise,
      meta: {
        subnav: 'luftbett',
        title: 'Preise von Luftkernmatratzen: Allgäuer Luftbett',
        description:
          'Preise von Luftkernmatratzen: Was kostet eine Luftkernmatratze? Preise für ein Allgäuer Luftbett abhängig von Bedarf und Ausstattung: alle Infos zu Preisen'
      }
    },
    {
      path: '/luftkernmatratze-garantie',
      name: 'garantie',
      component: Garantie,
      meta: {
        subnav: 'luftbett',
        title: 'Garantie für Luftkernmatratze: Allgäuer Luftbett',
        description:
          'Infos zur Garantie für Luftkernmatratze: Bis zu 10 Jahre Garantie auf unser Allgäuer Luftbett von Dynaglobe®: Einzelteile jederzeit austauschbar'
      }
    },
    {
      path: '/luftkernmatratze-einbau',
      name: 'einbau',
      component: Einbau,
      meta: {
        subnav: 'luftbett',
        title: 'Einbau von Luftkernmatratzen: schneller, einfacher Aufbau',
        description:
          'Einbau von Luftkernmatratzen: in 15 Minuten aufgebaut und einsatzbereit: Wir helfen Ihnen telefonisch oder allgäuweit vor Ort: Allgäuer Luftbett'
      }
    },
    {
      path: '/service',
      name: 'service',
      component: Service,
      meta: {
        subnav: 'service',
        title: 'Allgäuer Luftbett Service: Beratung, Probeliegen, Lieferung',
        description:
          'Luftkernmatratzen Allgäuer Luftbett: Unsere Serviceleistungen: professionelle Beratung, Probeliegen, Schlafberatung, Lieferung, Einbauservice vor Ort.'
      }
    },
    {
      path: '/schlafberatung',
      name: 'schlafberatung',
      component: Schlafberatung,
      meta: {
        subnav: 'service',
        title: 'Schlafberatung: Erstellung Ihres persönlichen Schlafprofils',
        description:
          'Schlafberatung: Erstellung individuelles Schlafprofil: Analyse Ihrer Schlafgewohnheiten und Feststellung von Schlafstörungen: Allgäuer Luftbett Service'
      }
    },
    {
      path: '/schlafprofil-erstellen',
      name: 'schlafprofil',
      component: Schlafprofil,
      meta: {
        subnav: 'service',
        title: 'Schlafberatung: Erstellung Ihres persönlichen Schlafprofils',
        description:
          'Schlafberatung: Erstellung individuelles Schlafprofil: Analyse Ihrer Schlafgewohnheiten und Feststellung von Schlafstörungen: Allgäuer Luftbett Service'
      }
    },
    {
      path: '/probeliegen',
      name: 'probeliegen',
      component: Probeliegen,
      meta: {
        subnav: 'service',
        title: 'Luftkernmatratze probeliegen: Allgäuer Luftbett testen',
        description:
          'Luftkernmatratze probeliegen: Testen Sie unser Allgäuer Luftbett: in unserem Fachgeschäft, zuhause im „Allgäuer-Luftbett-Mobil“, in Hotels, auf Messen'
      }
    },
    {
      path: '/luftkernmatratze-lieferung',
      name: 'lieferung',
      component: Lieferung,
      meta: {
        subnav: 'service',
        title: 'Lieferung unserer Luftkernmatratzen nach Terminabsprache',
        description:
          'Lieferung unserer Luftkernmatratzen: nach Terminabsprache per Spedition, kostenlos innerhalb Deutschlands: Aufbau auf Anfrage: Allgäuer Luftbett'
      }
    },
    {
      path: '/luftkernmatratze-fragen',
      name: 'faq',
      component: FAQ,
      meta: {
        title: 'FAQ: Fragen und Antworten zu unseren Luftkernmatratzen',
        description:
          'FAQ: Fragen und Antworten zu unseren Luftkernmatratzen: Alle Infos zum Thema gesundheitsfördernder Schlaf und ergonomische Schlafposition: Allgäuer Luftbett'
      }
    },
    {
      path: '/faq-produkt',
      name: 'faq-product',
      component: FaqProduct,
      meta: {
        title: 'Produktfragen zum Allgäuer Luftbett',
        description:
          'Hier finden Sie die häufigsten Produktfragen zum Allgäuer Luftbett bzw. zur Luftkernmatratze von Dynaglobe.'
      }
    },
    {
      path: '/faq-wartung-pflege',
      name: 'faq-maintenance',
      component: FaqMaintenance,
      meta: {
        title: 'Fragen zu Wartung und Pflege des Allgäuer Luftbetts',
        description:
          'Hier finden Sie häufige Fragen zu Wartung und Pflege des Allgäuer Luftbetts bzw. der Luftkernmatratze von Dynaglobe.'
      }
    },
    {
      path: '/faq-preis-und-garantie',
      name: 'faq-price',
      component: FaqPrice,
      meta: {
        title: 'Preis und Garantie beim Allgäuer Luftbett?',
        description:
          'Hier finden Sie häufige Fragen zum Preis und den Garantiebestimmungen beim Allgäuer Luftbett.'
      }
    },
    {
      path: '/faq-probeliegen',
      name: 'faq-testing',
      component: FaqTesting,
      meta: {
        title: 'Wo kann man das Allgäuer Luftbett probeliegen?',
        description:
          'Hier finden Sie häufige Fragen zu den Möglichkeiten des Probeliegens unserer Luftkernmatratze.'
      }
    },
    {
      path: '/faq-wasserbett-luftkernmatratze-vergleich',
      name: 'faq-comparison',
      component: FaqComparison,
      meta: {
        title: 'Wasserbett und Luftkernmatratze im Vergleich',
        description:
          'Was unterscheidet ein Wasserbett von einer hochwertigen Luftkernmatratze? Hier erfahren Sie mehr!'
      }
    },
    {
      path: '/faq-anlieferung-aufbau',
      name: 'faq-delivery',
      component: FaqDelivery,
      meta: {
        title: 'Lieferung unserer Luftkernmatratzen nach Terminabsprache',
        description:
          'Lieferung unserer Luftkernmatratzen: nach Terminabsprache per Spedition, kostenlos innerhalb Deutschlands: Aufbau auf Anfrage: Allgäuer Luftbett'
      }
    },
    {
      path: '/ueber-uns',
      name: 'ueber-uns',
      component: UeberUns,
      meta: {
        subnav: 'ueber-uns',
        title: 'Luftkernmatratze „Allgäuer Luftbett“: Über uns',
        description:
          'Die Philosophie hinter dem Allgäuer Luftbett: einzigartige Luftkernmatratze, bester Service, hohe Kundenorientierung und Kundenzufriedenheit: Über uns'
      }
    },
    {
      path: '/herkunftsgeschichte',
      name: 'herkunftsgeschichte',
      component: Herkunftsgeschichte,
      meta: {
        subnav: 'ueber-uns',
        title: 'Allgäuer Luftbett: Idee und Herkunftsgeschichte',
        description:
          'Herkunftsgeschichte des Allgäuer Luftbetts: Die Idee hinter der einzigartigen Luftkernmatratze: Wie die Idee zum Allgäuer Luftbett entstand'
      }
    },
    {
      path: '/newsletter',
      name: 'newsletter',
      component: Newsletter,
      meta: {
        title: 'Newsletter Allgäuer Luftbett: Hier bestellen und informieren',
        description:
          'Allgäuer Luftbett Newsletter: Wir informieren Sie regelmäßig über Preisaktionen und Neuigkeiten rund ums Thema gesunder Schlaf: Hier abonnieren.'
      }
    },
    {
      path: '/luftkernmatratze-erfahrungsberichte',
      name: 'kundenstimmen',
      component: Kundenstimmen,
      meta: {
        subnav: 'ueber-uns',
        title: 'Kundenstimmen und Erfahrungsberichte: Allgäuer Luftbett',
        description:
          'Kundenstimmen und Erfahrungsberichte rund ums Allgäuer Luftbett: So schläft es sich auf unserer Luftkernmatratze: Kundenstimmen, Videos, TV-Beiträge'
      }
    },
    {
      path: '/bericht/:postid',
      name: 'referenceid',
      component: Reference,
      props: true,
      meta: {
        theme: 'dark'
      }
    },
    {
      path: '/bericht/:postid/:slug',
      name: 'reference',
      component: Reference,
      props: true,
      meta: {
        theme: 'dark'
      }
    },
    {
      path: '/blog',
      name: 'blog',
      component: Blog
    },
    {
      path: '/blog/:postid',
      name: 'blogpostid',
      component: Blogpost,
      props: true
    },
    {
      path: '/blog/:postid/:slug',
      name: 'blogpost',
      component: Blogpost,
      props: true
    },
    {
      path: '/kontakt',
      name: 'kontakt',
      component: Kontakt
    },
    {
      path: '/impressum',
      name: 'impressum',
      component: Impressum
    },
    {
      path: '/datenschutz',
      name: 'datenschutz',
      component: Datenschutz
    },
    {
      path: '/:catchAll(.*)',
      component: NotFound,
      name: 'NotFound'
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})
