<template>
  <div>
    <div class="wrapper">
      <CopyBox headline="Wir sind" headlineBold="für Sie da">
        Wir freuen uns auf Ihre Nachricht über unser Kontaktformular und stehen
        Ihnen gerne Rede und Antwort.
      </CopyBox>

      <CopyBox
        align="full"
        headline="Schreiben Sie uns"
        headlineBold="eine Nachricht"
      ></CopyBox>

      <form name="contact" @submit.prevent="submit()">
        <div class="form">
          <div class="form-section">
            <BaseInput
              label="Name*"
              v-model="contact.name"
              type="text"
              placeholder="Ihr Vor- & Nachname"
              :class="{ error: $v.contact.name.$error }"
              @blur="$v.contact.name.$touch()"
            ></BaseInput>
            <BaseInput
              label="E-Mail*"
              v-model="contact.email"
              type="email"
              placeholder="Ihre E-Mail Adresse"
              :class="{ error: $v.contact.email.$error }"
              @blur="$v.contact.email.$touch()"
            ></BaseInput>
            <BaseInput
              label="Telefon"
              v-model="contact.phone"
              type="text"
              placeholder="Ihre Telefonnummer"
            ></BaseInput>
            <BaseInput
              label="PLZ*"
              v-model="contact.zip"
              type="text"
              placeholder="Ihre PLZ"
              max-length="5"
              :class="{ error: $v.contact.zip.$error }"
              @blur="$v.contact.zip.$touch()"
            ></BaseInput>
          </div>
          <div class="form-section padding-left">
            <BaseTextarea
              label="Nachricht*"
              v-model="contact.message"
              placeholder="Teilen Sie uns hier Ihr Anliegen mit"
              :class="{ error: $v.contact.message.$error }"
              @blur="$v.contact.message.$touch()"
            ></BaseTextarea>
            <BaseCheckbox
              v-model="contact.callback"
              label="Rückruf gewünscht"
              name="callback"
              >Wir rufen Sie gerne zurück</BaseCheckbox
            >
            <BaseCheckbox
              v-model="contact.privacy"
              name="privacy"
              label="Datenschutz*"
              :class="{ error: $v.contact.privacy.$error }"
              @change="$v.contact.privacy.$touch()"
            >
              Ich habe die
              <router-link to="datenschutz">Datenschutzerklärung</router-link
              >&nbsp;zur Kenntnis genommen. Ich stimme zu, dass meine Angaben
              und Daten zur Beantwortung meiner Anfrage elektronisch erhoben und
              gespeichert werden. Hinweis: Sie können Ihre Einwilligung
              jederzeit für die Zukunft per E-Mail an
              allgaeuer-luftbett@ulrich-schmid.de widerrufen.
            </BaseCheckbox>
          </div>
          <div class="form-section submit">
            <div class="errorMessage">
              <template v-if="$v.contact.$anyError">
                <span class="highlight"
                  >Ihre Nachricht kann noch nicht gesendet werden:</span
                >
                <br />Bitte überprüfen Sie die rot markierten Felder auf
                Vollständigkeit und Korrektheit.
              </template>
            </div>
            <BaseButton type="submit" :disabled="$v.contact.$anyError"
              >Jetzt senden</BaseButton
            >
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventBus'
import FormService from '@/services/FormService'
import CopyBox from '@/components/CopyBox.vue'
import { required, email, numeric, maxLength } from 'vuelidate/lib/validators'

export default {
  components: {
    CopyBox
  },
  data() {
    return {
      contact: {
        name: this.name,
        email: this.email,
        phone: this.phone,
        zip: this.zip,
        message: this.message,
        callback: this.callback,
        privacy: this.privacy
      }
    }
  },
  validations: {
    contact: {
      name: { required },
      email: { required, email },
      zip: {
        required,
        numeric,
        maxLength: maxLength(5)
      },
      message: { required },
      privacy: { required }
    }
  },
  methods: {
    submit() {
      this.$v.contact.$touch()

      if (!this.$v.contact.$invalid) {
        FormService.postContact(this.contact).then(response => {
          if (response.data.code == 202) {
            this.contact = {}
            this.$v.contact.$reset()
          }
        })
        /* .catch(error => {
            console.log('Axios error: ' + error.response)
          }) */
      }
    }
  },
  mounted() {
    EventBus.$emit('headline', 'Kontakt')
    EventBus.$emit('headlineExt', 'Schreiben Sie uns')
  }
}
</script>

<style lang="scss">
.form {
  display: flex;
  flex-flow: row wrap;

  div.form-section {
    flex: 1 0 50%;
    box-sizing: border-box;
  }
  .padding-left {
    padding-left: 50px;
  }
}
@include bp-medium {
  .form {
    display: block;
  }
  .padding-left {
    padding-left: 0 !important;
  }
}
.errorMessage {
  color: $color-red;
  font-size: 16px;
  margin-bottom: 25px;
}
.submit {
  text-align: center;
}
</style>
