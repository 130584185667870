<template>
  <div id="logo">
    <router-link :to="{ name: 'home' }">
      <img
        src="@/assets/logo_allgaeuer-luftbett.jpg"
        alt="Logo Allgäuer Luftbett"
      />
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'MainLogo'
}
</script>

<style lang="scss" scoped>
#logo {
  position: fixed;
  z-index: 997;
  top: 0;
  right: 12.5%;
  width: 220px;
  background-color: white;
  text-align: center;
  box-shadow: 0 0 10px #ccc;

  img {
    width: 85%;
    margin-top: 20px;
  }

  @include bp-small {
    position: absolute;
    top: auto;
    width: 140px;
    right: 7.5%;
    box-shadow: 0 5px 10px #ccc;
  }
}
</style>
