<template>
  <div>
    <dl class="faq">
      <div>
        <dt v-html="number + 1 + ' – ' + title" @click="open = !open"></dt>
        <dd v-html="text" v-show="open"></dd>
      </div>
    </dl>
  </div>
</template>

<script>
export default {
  name: 'AccordionItem',
  props: {
    title: String,
    text: String,
    number: Number
  },
  data: function() {
    return {
      open: false
    }
  }
}
</script>

<style lang="scss" scoped>
.faq div {
  padding: 20px;
  margin-bottom: 15px;
  background-color: $color-lighter-brown;
  color: $color-brown;
  position: relative;
  border-radius: 10px;
}
.faq dt {
  font-size: 18px;
  font-weight: 900;
  cursor: pointer;
}
.faq dd {
  margin-left: 0;
  padding-top: 15px;
  font-size: 18px;
  line-height: 1.5em;
  color: $color-black;
  display: block;
  overflow: hidden;
  transition: height 0.7s;
  -webkit-transition: height 0.7s;
}

.faq dd ul {
  display: inline;
  margin-left: 0;
}
</style>
