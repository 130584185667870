<template>
  <div class="formItem">
    <label v-if="label">{{ label }}</label>
    <input
      :value="value"
      @input="updateValue"
      @focus="isActive = true"
      @blur="isActive = false"
      :class="{ active: isActive }"
      v-bind="$attrs"
      v-on="listeners"
    />
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    value: [String, Number],
    label: {
      type: String,
      default: ''
    }
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue
      }
    }
  },
  methods: {
    updateValue(event) {
      this.$emit('input', event.target.value)
    }
  },
  data: function() {
    return {
      isActive: false
    }
  }
}
</script>

<style lang="scss" scoped>
.formItem {
  margin-bottom: 25px;
}
label {
  display: block;
  font-size: 14px;
  color: $color-brown;
}
input {
  font-size: 16px;
  padding: 10px;
  padding-left: 0;
  border: 0;
  border-bottom: 1px solid $color-light-brown;
  width: 100%;
  outline: none;
  box-sizing: border-box;
}
.active {
  border-bottom-color: $color-brown;
}
.error {
  input {
    border-bottom-color: $color-red;
    color: $color-red;
  }
}
</style>
