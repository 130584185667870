import { render, staticRenderFns } from "./FaqMaintenancePage.vue?vue&type=template&id=19d3a28c&scoped=true&"
import script from "./FaqMaintenancePage.vue?vue&type=script&lang=js&"
export * from "./FaqMaintenancePage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19d3a28c",
  null
  
)

export default component.exports