import { gql } from 'graphql-request'

const query = () => gql`
  query faqs($slug: String!) {
    faqs(where: { faqBlock_some: { slug: $slug } }) {
      id
      question
      answer {
        html
      }
    }
  }
`

export default {
  query
}
