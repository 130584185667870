<template>
  <div>
    <div class="wrapper">
      <CopyBox
        headline="Ergonomischer Schlaf und"
        headlineBold="höchster Schlafkomfort"
        align="left"
      >
        Wünschen auch Sie sich möglichst erholsamen,
        <span class="highlight">gesunden und ergonomischen Schlaf</span>? Das
        Allgäuer Luftbett ermöglicht mehr Liegekomfort und mehr Schlafkomfort.
        Unabhängig von Ihrer Schlafposition (Bauch oder Rücken) wird Ihre
        <span class="highlight">Wirbelsäule bestmöglich gelagert</span>, um die
        für wirklich erholsamen Schlaf notwendige Dehnung zu erhalten. Wir
        stellen die Luftkernmatratze aus dem Hause Dynaglobe in einem
        individuellen Beratungsgespräch
        <span class="highlight">maßgeschneidert auf Ihre Bedürfnisse</span> ab,
        indem wir Ihnen bei der Auswahl verschiedener Einzelkomponenten helfen.
      </CopyBox>
      <CopyBox
        align="full"
        headline="Der Aufbau unserer"
        headlineBold="Luftkernmatratze"
      />
      <div class="dynaglobe-iframe">
        <iframe
          border="0"
          style="border: 0px; width: 100%; height: 100vh;"
          src="https://dynaglobe.de/integration/index.php"
        ></iframe>
      </div>
      <CopyBox align="right">
        Auf den folgenden Unterseiten erhalten Sie einen Überblick über die
        Eigenschaften dieses einzigartigen Schlafsystems: Erfahren Sie mehr über
        die zahlreichen
        <router-link :to="{ name: 'vorteile' }"
          >Vorteile unseres einzigartigen Schlafsystems</router-link
        >. Wir verraten Ihnen auch, wie
        <router-link :to="{ name: 'pflege' }"
          >leicht unsere Luftkernmatratze zu reinigen</router-link
        >
        ist und stellen Ihnen eine Pflegeanleitung zum Download bereit.
        Sicherlich möchten Sie auch erfahren,
        <router-link :to="{ name: 'preise' }"
          >was ein Allgäuer Luftbett kostet</router-link
        >
        und wie es mit der
        <router-link :to="{ name: 'garantie' }">Garantie</router-link> aussieht.
        Und falls Sie mit dem Gedanken einer Anschaffung spielen ist es
        natürlich auch interessant zu erfahren, wie der
        <router-link :to="{ name: 'einbau' }">
          Einbau unserer Luftkernmatratze</router-link
        >
        erfolgt. Falls nach der Lektüre unserer Website immer noch Fragen offen
        sein sollten, sind wir jederzeit für Sie da – schreiben Sie uns einfach
        eine E-Mail oder rufen Sie uns an.
        <p>
          <CTA link="vorteile" text="Mehr erfahren"></CTA>
        </p>
      </CopyBox>
      <QuoteTeaser headlineBold="Georgette A." headline="– Kundin">
        <p>
          "Ich habe erst eine Nacht auf der neuen Matratze geschlafen und habe
          jetzt schon festgestellt dass ich viel ruhiger geschlafen habe; ohne
          ständige Drehbewegungen meinerseits.“
        </p>
      </QuoteTeaser>
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventBus'
import CopyBox from '@/components/CopyBox.vue'
import QuoteTeaser from '@/components/QuoteTeaser.vue'
import CTA from '@/components/CTA.vue'

export default {
  components: {
    CopyBox,
    QuoteTeaser,
    CTA
  },
  mounted() {
    EventBus.$emit('headline', 'Allgäuer Luftbett')
    EventBus.$emit('headlineExt', 'Ihr Bett fürs Leben')
  }
}
</script>

<style lang="scss"></style>
