<template>
  <div class="list">
    <ol>
      <slot></slot>
    </ol>
  </div>
</template>

<script>
export default {
  name: 'OrderedList'
}
</script>

<style lang="scss" scoped>
.list {
  ol {
    margin: 0;
    padding: 0;
    display: flex;
    flex-flow: row wrap;
    list-style: none;
    counter-reset: list-counter;

    li {
      counter-increment: list-counter;
      box-sizing: border-box;
      flex: 0 1 50%;
      max-width: 50%;
      margin-bottom: 30px;
    }
    li:nth-child(odd) {
      padding-right: 15px;
    }
    li:nth-child(even) {
      padding-left: 15px;
    }
    li::before {
      content: counter(list-counter);
      font-size: 40px;
      font-weight: 900;
      color: $color-light-brown;
      padding-right: 10px;
    }
  }
}
</style>
