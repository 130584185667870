<template>
  <div>
    <slot name="logo"></slot>
    <div class="stage-full" :class="imageClass">
      <div class="wrapper">
        <slot name="nav"></slot>
        <StageHeadline
          slot="headline"
          :h1="headline"
          :h1e="headlineExt"
          :h2="subline"
        />
      </div>
    </div>
    <div class="connect">
      <img src="../assets/connector.svg" />
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventBus'
import StageHeadline from '@/components/StageHeadline.vue'

export default {
  name: 'FullStage',
  computed: {
    imageClass: function() {
      return this.$route.name
    }
  },
  components: {
    StageHeadline
  },
  data: function() {
    return {
      headline: '',
      headlineExt: '',
      subline: '',
      stageImage: ''
    }
  },
  mounted() {
    let that = this
    EventBus.$on('headline', function(headline) {
      that.headline = headline
    })
    EventBus.$on('headlineExt', function(headlineExt) {
      that.headlineExt = headlineExt
    })
    EventBus.$on('subline', function(subline) {
      that.subline = subline
    })
  }
}
</script>

<style lang="scss" scoped>
.stage-full {
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  z-index: 0;
  background-size: cover;
  background-position: center;
  background-image: url('../assets/stage_home.jpg');

  @include bp-medium {
    height: 50vh;
    padding-top: 30px;
    box-sizing: border-box;
  }
}
.connect {
  margin-top: -75px;
  width: 100%;
  text-align: center;

  img {
    height: 150px;
  }

  @include bp-small {
    margin-top: -40px;

    img {
      height: 80px;
    }
  }
}
.home {
  background-image: url('../assets/stage_home.jpg');
}
.service {
  background-image: url('../assets/stage_hotellerie.jpg');
}
.schlafberatung {
  background-image: url('../assets/stage_hotellerie.jpg');
}
.schlafprofil {
  background-image: url('../assets/stage_hotellerie.jpg');
}
.probeliegen {
  background-image: url('../assets/stage_hotellerie.jpg');
}
.lieferung {
  background-image: url('../assets/stage_hotellerie.jpg');
}
.blog {
  background-image: url('../assets/stage_blog.jpg');
}
.blogpost {
  background-image: url('../assets/stage_blogpost.jpg');
}
.blogpostid {
  background-image: url('../assets/stage_blogpost.jpg');
}
.faq {
  background-image: url('../assets/stage_faq.jpg');
}
.faq-comparison {
  background-image: url('../assets/stage_indiv.jpg');
}
.ueber-uns {
  background-image: url('../assets/stage_ueber-uns.jpg');
}
.kundenstimmen {
  background-image: url('../assets/stage_ueber-uns.jpg');
}
.reference {
  background-image: url('../assets/stage_ueber-uns.jpg');
}
.referenceid {
  background-image: url('../assets/stage_ueber-uns.jpg');
}
.herkunftsgeschichte {
  background-image: url('../assets/stage_ueber-uns.jpg');
}
.kontakt {
  background-image: url('../assets/stage_kontakt.jpg');
}
.impressum {
  background-image: url('../assets/stage_kontakt.jpg');
}
</style>
