<template>
  <div>
    <div class="wrapper">
      <CopyBox
        align="full"
        headline="Beratung, Probe liegen, liefern lassen:"
        headlineBold="Unser Luftkernmatratzen-Service"
      >
        Uns geht es nicht darum, Matratzen zu verkaufen. Uns ist wichtig, dass
        unsere Kunden erholsamen Schlaf finden, der dem Bewegungsapparat genauso
        gut tut, wie dem Geist. Den hohe Schlafqualität wirkt sich positiv auf
        die gesamte Lebensqualität aus. Aus diesem Grund sind wir in jeder Phase
        des Matratzenkaufs für Sie da: Unsere Serviceleistungen reichen von
        einer qualifizierten
        <router-link :to="{ name: 'schlafberatung' }"
          >Schlafberatung</router-link
        >&nbsp;und
        <router-link :to="{ name: 'schlafprofil' }"
          >Erstellung eines Schlafprofils</router-link
        >&nbsp;über die Matratzenberatung und verschiedene Möglichkeiten zum
        <router-link :to="{ name: 'probeliegen' }">Probeliegen</router-link
        >&nbsp; bis zur Beratung beim Aufbau und Einbau unserer Luftkernmatratze
        in Ihr vorhandenes Bettgestell gleich nach der
        <router-link :to="{ name: 'lieferung' }">Lieferung</router-link>. Und
        auch danach sind wir für unsere Kunden da und geben Tipps zur Nutzung –
        z.B. wenn sich Fragen zu den Einstellmöglichkeiten ergeben. Es freut uns
        immer, wenn sich Kunden bei uns melden und von ihren Erfahrungen mit
        unserer einzigartigen Matratze berichten. Dank zahlreicher Feedbacks ist
        unser Erfahrungspool über die Jahre stark gewachsen. Möchten Sie sich
        selbst von unserer hohen Servicequalität überzeugen und in den Genuss
        einer individuellen Schlafberatung kommen? Dann nehmen Sie jetzt gleich
        Kontakt mit uns auf – entweder per E-Mail oder telefonisch. Wir freuen
        uns auf Sie und helfen Ihnen gerne dabei, Ihre Schlafqualität zu
        verbessern.
      </CopyBox>
      <QuoteTeaser headlineBold="Helmut H." headline="– Kunde">
        <p>
          "Bezüglich Beratung und Service kann ich Herrn Schmid nur die besten
          Noten ausstellen. Sei es vom Erstgespräch bis hin zur Unterstützung
          beim Aufbau des Bettes.“
        </p>
      </QuoteTeaser>
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventBus'
import CopyBox from '@/components/CopyBox.vue'
import QuoteTeaser from '@/components/QuoteTeaser.vue'

export default {
  components: {
    CopyBox,
    QuoteTeaser
  },
  mounted() {
    EventBus.$emit('headline', 'Luftkernmatratze')
    EventBus.$emit('headlineExt', 'mit freundlichem Service!')
  }
}
</script>

<style lang="scss"></style>
