<template>
  <span>
    <button :class="isInverted" v-bind="$attrs">
      <slot></slot>
    </button>
  </span>
</template>

<script>
export default {
  name: 'BaseButton',
  props: {
    text: {
      type: String
    },
    inverted: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isInverted: function() {
      if (this.inverted === true) {
        return 'inverted'
      }
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
button {
  box-sizing: border-box;
  padding: 13px 25px;
  border: 2px solid;
  border-radius: 50px;
  outline: none;
  text-decoration: none;
  font-size: 18px;
  font-weight: 900;
  display: inline-block;
  cursor: pointer;

  &.inverted {
    border-color: white;
    color: white;
  }
  &:hover {
    background-color: white;
    color: $color-brown;
  }
  &:disabled {
    color: $color-light-brown;
  }
}
@include bp-small {
  button {
    padding: 10px 20px;
    font-size: 16px;
    text-align: center;
  }
}
</style>
