<template>
  <div>
    <div class="wrapper">
      <CopyBox>
        <p>
          Neigen auch Sie zu Schlafproblemen? Damit wären Sie im
          deutschsprachigen Raum nicht alleine.
          <span class="highlight">Studien von Krankenkassen</span> belegen immer
          wieder: Unter Arbeitnehmern sind Schlafprobleme heutzutage weit
          verbreitet. Provokante Frage:
          <span class="highlight">
            Kann es sein, dass viele Schlafprobleme zum Teil auch einfach an
            einer völlig ungeeigneten Matratze liegen?
          </span>
          Oder an Schlafgewohnheiten, die erholsames Schlafen erschweren?
        </p>
      </CopyBox>
      <CopyBox align="right">
        <p>
          Wir sind auf
          <router-link :to="{ name: 'schlafberatung' }"
            >individuelle Schlafberatung</router-link
          >
          sowie den
          <router-link :to="{ name: 'schlafberatung' }"
            >Verkauf von Luftkernmatratzen</router-link
          >
          und <span class="highlight">luftgefederten Matratzen</span> vom
          Hersteller Dynaglobe® spezialisiert. Diese einzigartigen Matratzen mit
          integrierten Luftkammern bieten aufgrund ihrer
          <span class="highlight">ergonomischen Anpassungsfähigkeit</span>
          höchsten Schlafkomfort und damit einem gesunden, erholsamen Schlaf.
        </p>
        <p>
          <CTA link="allgaeuer-luftbett" text="Mehr zur Luftkernmatratze"></CTA>
        </p>
      </CopyBox>
      <CopyBox headline="Professionelle" headlineBold="Schlafberatung">
        <p>
          Was uns von anderen Matratzenverkäufern unterscheidet ist v.a. eine
          kompetente
          <router-link :to="{ name: 'schlafberatung' }"
            >Schlafberatung</router-link
          >, bei der wir <span class="highlight">individuell</span> auf Sie
          eingehen. Dabei ist uns wichtig, dass Sie als Interessent sich wohl
          fühlen. Natürlich erläutern wir Ihnen Rahmen dieser Schlafberatung
          gern auch die Vorzüge unserer
          <span class="highlight">Matratze mit Luftkammer</span> im Inneren.
          Denn mit diesem System lassen sich tatsächlich viele Ursachen für
          Schlafprobleme behandeln – lesen Sie gerne mal ein paar
          <router-link :to="{ name: 'kundenstimmen' }"
            >Kundenstimmen</router-link
          >.
        </p>
      </CopyBox>
      <CopyBox
        align="right"
        headline="Konfiguration Ihres"
        headlineBold="individuellen Schlafsystems"
      >
        <p>
          Auf Wunsch helfen wir Ihnen bei der Auswahl Ihres individuellen
          Schlafsystems und stellen Ihnen Ihr
          <span class="highlight">„Bett fürs Leben“</span> aus den für Ihren
          Bedarf passenden Komponenten zusammen. Doch wir üben keinerlei
          Verkaufsdruck aus. Das ist Teil unserer
          <router-link :to="{ name: 'herkunftsgeschichte' }"
            >Unternehmensphilosophie</router-link
          >. Bei uns wird nur gekauft, wenn Sie
          <span class="highlight">100% vom Produkt überzeugt sind</span>–
          versprochen! Es ist vollkommen in Ordnung wenn Sie das "Gefühl wie zu
          Schweben" auf unserem
          <span class="highlight">Luftschlafsystem der Premiumklasse</span>
          einfach nur mal unverbindlich erleben oder uns ein paar Fragen stellen
          möchten.
        </p>
      </CopyBox>
    </div>

    <CtaTeaser
      headline="Unverbindliches"
      headlineBold="Probeliegen"
      ctaText="Unverbindlich Probeliegen"
      ctaLink="probeliegen"
    >
      <p>
        Da diese Form des Schlafens in einer breiten Bevölkerungsschicht noch
        weitgehend unbekannt ist, freuen wir uns über das Interesse jedes
        Einzelnen an diesem
        <span class="highlight">einzigartigen Produkt</span>, das selbst unsere
        Augen nach all den Jahren noch zum Leuchten bringt. Darum sehen Sie sich
        gerne erstmal in Ruhe hier auf unserer Website um.
        <router-link :to="{ name: 'kontakt' }"
          >Melden Sie sich gerne</router-link
        >&nbsp;bei näherem Interesse. Wir sind gerne auch persönlich für Sie da
        oder ermöglichen Ihnen ein
        <span class="highlight">unverbindliches Probeliegen</span>.
      </p>
    </CtaTeaser>
    <div class="wrapper">
      <CopyBox
        align="right"
        headline="Luftkernmatratze mit"
        headlineBold="flexiblen Härtegraden"
      >
        <p>
          Was unsere Luftkernmatratze von herkömmlichen Schlafsystemen
          unterscheidet? Der flexible Härtegrad: Im Unterschied zu anderen
          Schlafsystemen hat diese Matratze
          <span class="highlight">zwei Luftkerne im Inneren</span>. Bei unserem
          hochwertig verarbeiteten Luftschlafsystem aus dem Hause Dynaglobe®
          müssen Sie sich beim Matratzenkauf nicht mehr für einen bestimmten
          Härtegrad entscheiden, denn die
          <span class="highlight">Festigkeit (Härte)</span> des
          strapazierfähigen Luftkerns ist jederzeit
          <span class="highlight">auf Knopfdruck veränderbar</span>.
        </p>
      </CopyBox>
      <CopyBox>
        <p>
          Unsere sehr langlebige Luftkernmatratze passt sich jedem
          Bewegungsapparat optimal an und unterstützt einen ergonomischen Schlaf
          und eine ergonomische Lagerung in jeder Schlafposition (ob auf dem
          Rücken, der Seite oder dem Bauch). Außerdem ist sie leicht zu
          transportieren, zu pflegen und zu reinigen. Das ermöglicht einen
          äußerst hohen Hygienestandard.
        </p>
      </CopyBox>
      <QuoteTeaser headlineBold="Albert A." headline="– Kunde">
        <p>
          "Auf verschiedenen Messen habe ich das Luftbett gesehen. Dann habe ich
          es mir von Herrn Uli Schmid genauestens erklären lassen. Was im
          Oktober 2015 zum Kauf dieser einmaligen Luftkernmatratze geführt hat.
          Danach haben sich meine Frau und Tochter dazu entschieden, ebenfalls
          das Allgäuer Luftbett zu kaufen. Ich kann nur noch sagen: Genau die
          richtige Entscheidung – Schmerzen ade … Schlaf pur!“
        </p>
      </QuoteTeaser>
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventBus'
import CopyBox from '@/components/CopyBox.vue'
import CtaTeaser from '@/components/CtaTeaser.vue'
import QuoteTeaser from '@/components/QuoteTeaser.vue'
import CTA from '@/components/CTA.vue'

export default {
  components: {
    CopyBox,
    CtaTeaser,
    QuoteTeaser,
    CTA
  },
  mounted() {
    EventBus.$emit('headline', 'Allgäuer Luftbett')
    EventBus.$emit('headlineExt', 'Luftkernmatratze made in Germany')
  }
}
</script>

<style lang="scss"></style>
