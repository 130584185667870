<template>
  <div>
    <div class="wrapper">
      <ImageBox
        filename="Uli_Schmid.jpg"
        align="right"
        headline="Die Philosophie hinter"
        headlineBold="dem Allgäuer Luftbett "
      >
        <p>
          Wie Sie in unserer
          <router-link :to="{ name: 'herkunftsgeschichte' }"
            >Herkunftsgeschichte</router-link
          >&nbsp; nachlesen können, geht es weder bei unserem Unternehmen
          Allgäuer Luftbett noch bei unserem Luftkernmatratzen-Hersteller
          Dynaglobe® nur ums Geldverdienen. Wir sind
          <span class="highlight">überzeugt von unserem Produkt</span>
          – wir nutzen es selbst täglich – und im Mittelpunkt stehen Sie als
          Kunde und Ihr erholsamer Schlaf. Wir wissen um den z. T. schwierigen
          und wenig vertrauenserweckenden Matratzenmarkt und die oft fehlende
          Orientierungsmöglichkeit bei der Suche nach einer neuen Schlafstätte –
          weshalb ein Matratzenkauf schnell zum „Lotteriespiel“ werden kann.
        </p>
      </ImageBox>
      <ImageBox
        align="left"
        filename="Claudia_Schmid.jpg"
        headline="Begeisterung"
        headlineBold="beginnt mit Überzeugung"
      >
        <p>
          Essentieller Bestandteil unserer Servicephilosophie ist, dass Sie als
          Kunde nur dann kaufen, wenn Sie eine ordentliche Beratung erfahren
          haben und unser Produkt verstanden haben. Hinzukommend sollten Sie vor
          einer Kaufentscheidung mindestens eine Nacht über die Anschaffung
          eines Allgäuer Luftbetts geschlafen haben und wirklich zu 100% von
          unserem Produkt überzeugt sein. Denn nur dann werden Sie ebenso
          begeistert von unserem
          <span class="highlight">einzigartigen Schlafsystem</span> sein wie die
          vielen Kunden vor Ihnen. Von Vertrieb um jeden Preis, Hochdruckverkauf
          oder dem „schnellen Geld“ halten wir nichts. Wir setzen stattdessen
          auf <span class="highlight">hohe Kundenzufriedenheit</span> und
          lohnende Weiterempfehlungen.
        </p>
      </ImageBox>
      <QuoteTeaser headlineBold="Claudia Z." headline="– Kundin">
        <p>
          "Herr Schmid ist ein sehr angenehmer Mensch. Bei ihm hat man wirklich
          das Gefühl, er ist überzeugt von dem Allgäuer Luftbett, was sich dann
          bestätigt, wenn man dann das Allgäuer Luftbett zu Hause hat. Er ist
          auch nach dem Kauf immer erreichbar und steht mit Rat und Tat zur
          Seite.“
        </p>
      </QuoteTeaser>
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventBus'
import QuoteTeaser from '@/components/QuoteTeaser.vue'
import ImageBox from '@/components/ImageBox.vue'

export default {
  components: {
    QuoteTeaser,
    ImageBox
  },
  mounted() {
    EventBus.$emit('headline', 'Über uns:')
    EventBus.$emit('headlineExt', 'Luftkernmatratzen aus Überzeugung!')
  }
}
</script>

<style lang="scss"></style>
